import { LANGUAGES } from '@/constants/constants';
import { ENUM_MODES, ENUM_ORDER_STATUS_CONSTANTS, ENUM_TIER, ENUM_STATUS_CONSTANTS } from '@/enums/Enums';
import { BookingPageState, ExtendedOrderT, ExtendedReservationT, ExtendedTableT, ExtendedVenueSettingsT, Full, ModalState, ModifiedExtendedMenuItemT, ModifiedExtendedReservationT, OrdersDashboardState, ReservationsDashboardState, TablePageState, TableT } from '@/types/globalTypes';
import { checkTimeOverlap } from '@/components/shared/ResourceSchedulerComponent/helperFunctions';

export const updateState = <T>(state: T, value: T | ((prev: T) => T)): T => {
    return typeof value === 'function' ? (value as (prev: T) => T)(state) : value;
};

export const getDefaultExtendedReservationStructure = (): ModifiedExtendedReservationT => {
    return {
        id: '',
        email: '',
        extra_information: '',
        name_of_the_person: '',
        date_of_reservation: '',
        people: 0,
        phone_number: '',
        status: '',
        is_temporary: false,
        is_walk_in: false,
        table: getDefaultTableStructure(),
        actions: '',
        is_static: false,
        agreed_to_terms: false,
        created_at: '',
        end_time: '',
        guest_id: '',
        start_time: '',
        waiter_responded: false,
        hasPendingOrder: false,
        pin_code: '',
        skip_auto_pin_renewal: false,
        table_id: '',
        venue_id: '',
        is_active: false,
        preferred_locale: LANGUAGES.EN,
        preferred_table_type: ENUM_TIER.STANDARD,
    }
}

// Type guard to check if table is of type TableT
export const isTable = (table: TableT | null | string): table is TableT => {
    // Check if 'table' is not null and not a string
    return table !== null && typeof table !== 'string'
}

export const getDefaultTableStructure = (): ExtendedTableT => {
    return {
        floor: '',
        id: '',
        styles: {},
        x_coordinate: 0,
        y_coordinate: 0,
        reservation: {
            id: '',
            is_active: false,
            email: '',
            extra_information: '',
            name_of_the_person: '',
            date_of_reservation: '',
            people: 0,
            phone_number: '',
            status: '',
            is_temporary: false,
            is_walk_in: false,
            actions: '',
            is_static: true,
            agreed_to_terms: false,
            created_at: '',
            end_time: '',
            guest_id: '',
            start_time: '',
            waiter_responded: false,
            hasPendingOrder: false,
            pin_code: '',
            skip_auto_pin_renewal: false,
            table_id: '',
            venue_id: '',
            preferred_locale: LANGUAGES.EN,
            created_by_role: '',
            preferred_table_type: ENUM_TIER.STANDARD,
        },
        reserved: false,
        section: '',
        table_name: '',
        created_at: '',
        is_static: false,
        section_id: '',
        static_reservation_id: '',
        table_date: '',
        waiter: '',
        waiter_name: '',
        venue_id: '',
        max_capacity: 1,
        min_capacity: 1,
        base_fee: 0,
        extra_information: '',
        hourly_rate: 0,
        is_premium: false,
        minimum_spend: 0,
        table_type: ENUM_TIER.STANDARD,
        pricing_rules: {},
        is_reservable: true,
        max_duration: null,
        min_duration: null
    }
}

export const getDefaultVenueSettings = (): ExtendedVenueSettingsT => {
    return {
        address: '',
        allow_reservations: false,
        banner_url: null,
        capacity: null,
        currency: null,
        description: null,
        duration: null,
        email: null,
        facebook_link: null,
        id: '',
        initial_setup: null,
        instagram_link: null,
        languages: null,
        latitude: null,
        linkedin_link: null,
        logo_url: null,
        longitude: null,
        map_url: null,
        max_calendar_days: 30,
        name: '',
        online_payments: null,
        payment_methods: null,
        phone_number: null,
        reservation_lead_time: '00:00',
        skip_auto_pin_renewal: false,
        tiktok_link: null,
        venue_timezone: '',
        venue_type: null,
        website: null,
        youtube_link: null,
        working_hours: {
            0: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 0, venue_id: '' },
            1: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 1, venue_id: '' },
            2: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 2, venue_id: '' },
            3: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 3, venue_id: '' },
            4: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 4, venue_id: '' },
            5: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 5, venue_id: '' },
            6: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 6, venue_id: '' },
        },
        active_working_hours: {
            starting_hours: '00:00',
            ending_hours: '00:00',
            is_active: false,
            day_of_week: 0,
            venue_id: ''
        },
        starting_hours: '00:00',
        ending_hours: '00:00',
        days_of_operation: [],
        allow_table_selection: true,
        require_manual_approval: false,
        allow_same_day_reservations: false,
    }
};

export const getInitialReservationState = (): ModifiedExtendedReservationT => {
    return {
        id: '',
        is_active: false,
        email: '',
        extra_information: '',
        name_of_the_person: '',
        date_of_reservation: '',
        people: 0,
        phone_number: '',
        status: '',
        is_temporary: false,
        is_walk_in: false,
        actions: '',
        is_static: false,
        agreed_to_terms: false,
        created_at: '',
        end_time: '',
        guest_id: '',
        start_time: '',
        waiter_responded: false,
        hasPendingOrder: false,
        skip_auto_pin_renewal: false,
        table_id: null,
        venue_id: '',
        preferred_locale: LANGUAGES.EN,
        preferred_table_type: ENUM_TIER.STANDARD,

    }
}

export const getInitialOrderState = (): ExtendedOrderT => {
    return {
        reservation_id: '',
        order_items: [],
        waiter: '',
        guest_id: '',
        last_updated_by: '',
        order_number: 0,
        paid_online: false,
        receipt_id: '',
        receipt_number: 0,
        venue_id: '',
        reservation: getDefaultExtendedReservationStructure(),
        special_requests: '',
        total_items_quantity: 0,
        total_price: 0,
        waiter_name: '',
        id: '',
        status: '',
        created_at: '',
        updated_at: '',
    }
}

export const getInitialSelectedTableState = (): ExtendedTableT => {
    return {
        floor: '',
        id: '',
        styles: {},
        x_coordinate: 0,
        y_coordinate: 0,
        venue_id: '',
        reservation: getDefaultExtendedReservationStructure(),
        reserved: false,
        created_at: '',
        is_static: false,
        static_reservation_id: '',
        table_date: '',
        waiter: '',
        section: '',
        table_name: '',
        section_id: '',
        waiter_name: '',
        max_capacity: 1,
        min_capacity: 1,
        base_fee: 0,
        extra_information: '',
        hourly_rate: 0,
        is_premium: false,
        minimum_spend: 0,
        table_type: ENUM_TIER.STANDARD,
        pricing_rules: {},
        is_reservable: true,
        max_duration: null,
        min_duration: null

    }
}

export const getInitialDashboardStateState = (): OrdersDashboardState => {
    return {
        tablesData: [],
        orders: [],
        floors: [],
        sections: [],
        reservations: [],
        status: ENUM_ORDER_STATUS_CONSTANTS.NONE,
        loading: false,
        selectedMenuItems: [],
        selectedItemMode: ENUM_MODES.CREATE,
        orderMode: ENUM_MODES.CREATE,
        selectedOrderSingleItem: {
            id: '',
            menu_item_number: 0,
            venue_id: '',
            has_nutritional_data: false,
            is_active: true,
            is_optional: false,
            options: [],
            quantity: 0,
            unique_id: '',
            price: 0,
            image_url: '',
            created_at: '',
            updated_at: '',
            allergens: [],
            preparation_time: 0,
            availability: true,
            sizes: [''],
            promotion: 0,
            inventory: 0,
            maxinventory: 0,
            has_options: false,
            track_inventory: false,
            name: '',
        },
        searchedOrders: [],
        searchValue: '',
        initialPageLoad: true,
        offset: 0,
        isLoading: false,
        searchLoading: false,
        hasMore: true,
    }
};

export const getInitialReservationsDashboardState = (): ReservationsDashboardState => {
    return {
        tablesData: [],
        orders: [],
        floors: [],
        sections: [],
        reservations: [],
        status: ENUM_STATUS_CONSTANTS.PENDING,
        loading: false,
        initialPageLoad: true,
        selectedFlatRows: [],
        reservationsCountPerDay: {},
        events: [],
        selectedEvents: [],
        showTableComponent: false,
        lockCurrentShift: false,
        selectedDate: new Date(),
        dateToday: new Date(),
        minDate: new Date(),
        maxDate: new Date(),
        starting_hours: "",
        ending_hours: "",
    }
};

export const getInitialBookingPageState = (): BookingPageState => {
    return {
        reservations: [],
        tablesData: [],
        floors: [],
        sections: [],
        events: [],
        selectedEvents: [],
        selectedTable: getInitialSelectedTableState(),
        selectedTimePreferences: null,
        loading: false,
        showTimePreferences: false,
        showFloorPlanMap: false,
        reservationSteps: 0,
        confirmedReservationPopup: false,
        initialPageLoad: true,
        selectedDate: null,
        dateToday: null,
        minDate: null,
        maxDate: null,
        ending_hours: '',
        starting_hours: '',
        reservationAction: null,
        availableTablesData: [],
        selectedReservation: getDefaultExtendedReservationStructure(),
        tableTypeAvailability: []
    }
}



export const getInitialModalState = (): ModalState => {
    return {
        reservationAction: null,
        openReservationActionsConfirmationPopup: false,
        openResendConfirmationEmailPopup: false,
        openDisableStaticReservationPopup: false,
        openCustomerFloorPlanMapPopup: false,
        openReservationScheduler: false,
        openReservationActionsPopup: false,
        openPrintQrCodePopup: false,
        openViewTablePopup: false,
        checkOutStep: 0,
        openActionsPopup: false,
        openCancelActionPopup: false,
        openCheckoutPopup: false,
        openCompleteReservationPopup: false,
        paymentActionsPopup: false,
        openDeleteTablePopup: false,
        openEditTablePopup: false,
        openCopyDefaultTableLayoutPopup: false,
        openCreateTablePopup: false,
        openViewPopup: false,
        openViewTableOrdersPopup: false,
        orderActionsPopup: false,
        selectedOrderPopup: false,
        createOrderPopup: false,
        removeItemPopup: false,
        menuAddonsPopup: false,
        isTableToBeReserved: false,
        isReservationToBeRelocated: false,
        openCancelOrderPopup: false,
        openCreateWalkInReservationPopup: false,
        openAssignReservationToTablePopup: false,
        openAssignWaiterToTablePopup: false,
        openUnassignWaiterToTablePopup: false,
        openConfirmActionPopup: false,
        openHandleAcceptReservationRequestPopup: false,
        openHandleDeclineReservationRequestPopup: false,
        openMoveReservationToAnotherTablePopup: false,

        openGenerateNewPinCode: false,
        openAutoRenewalPinCodePopup: false,
        openScanReservationPopup: false,
        openScanErrorPopup: false,
        openDrawer: false,
        openCreateFloorPopup: false,
        openEditFloorPopup: false,
        openDeleteFloorPopup: false,
        openCreateSectionPopup: false,
        openEditSectionPopup: false,
        openDeleteSectionPopup: false,
        openUpdateLayoutPopup: false,
        selectedTable: null,
        orders: [],
        buttons: [],
        selectedOrder: null,
        selectedMenuItems: [],
        loading: false,
        profile: null,
        selectedOrderSingleItem: {} as ModifiedExtendedMenuItemT,
        selectedItemMode: ENUM_MODES.CREATE,
        openMenuPopup: false,
        orderMode: ENUM_MODES.CREATE,
        selectedReservation: null,
        singleOrderItemActionButtonsArray: [],
        venuePrefix: '',
        reserveTablePopupButtons: [],
        assignTableToWaiterButtons: [],
        unassignTableToWaiterButtons: [],
        acceptTableActionButtons: [],
        handleAcceptReservationRequestButtonsArray: [],
        handleDeclineReservationRequestButtonsArray: [],
        removeReservationPopupButtons: [],
        completeReservationPopupButtons: [],
        generateNewPinCodeButtons: [],
        openCreateReservationPopup: false,
        openDeleteReservationPopup: false,
        openEditReservationPopup: false,
        openEventsDetailPopup: false,
        openViewReservationPopup: false,
        scanErrorMessage: ''
    }

};



export const getDefaultSelectedOrderSingleItem = (): ModifiedExtendedMenuItemT => {
    return {
        id: '',
        has_nutritional_data: false,
        is_active: true,
        is_optional: false,
        options: [],
        quantity: 0,
        unique_id: '',
        price: 0,
        image_url: '',
        created_at: '',
        updated_at: '',
        allergens: [],
        preparation_time: 0,
        availability: true,
        sizes: [''],
        promotion: 0,
        inventory: 0,
        maxinventory: 0,
        has_options: false,
        track_inventory: false,
        name: '',
        menu_item_number: 0,
        venue_id: '',
    }
}

export const getInitialTablePageState = (): TablePageState => {
    return {
        reservation: getDefaultExtendedReservationStructure(),
        tableData: getDefaultTableStructure(),
        orders: [],
        floors: [],
        sections: [],
        selectedMenuItems: [],
        selectedItemMode: ENUM_MODES.CREATE,
        orderMode: ENUM_MODES.CREATE,
        selectedOrderSingleItem: getDefaultSelectedOrderSingleItem(),
        actionType: '',
        selectedOrder: getInitialOrderState(),
        loading: true
    }
}

export const isSameTable = (reservation: ExtendedReservationT, table: ExtendedTableT | TableT) =>
    reservation.table_id === table.id || reservation.id === table.static_reservation_id;

export const isActiveReservation = (reservation: ExtendedReservationT) =>
    reservation.is_active && reservation.is_static === false;

export const isStaticReservation = (reservation: ExtendedReservationT, table: ExtendedTableT | TableT) =>
    table.is_static && table.static_reservation_id === reservation.id;

/**
 * Converts a time string (HH:MM:SS) to minutes for easier time comparisons
 */
export const normalizeTimeToMinutes = (timeString: string): number => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number)
    return hours * 60 + minutes
}

/**
 * Finds the reservation closest to current time
 * @param tableReservations Reservations to search through
 * @param currentTimeMinutes Current time in minutes (optional, defaults to now)
 * @param shouldLog Whether to log debugging information
 * @returns The closest reservation or null if none found
 */
export const findClosestReservationByTime = (
    tableReservations: ExtendedReservationT[],
    currentTimeMinutes?: number,
    shouldLog: boolean = false
): ExtendedReservationT | null => {
    // No reservations to check
    if (!tableReservations.length) {
        if (shouldLog) console.log('No reservations to check for this table');
        return null;
    }

    // Get current time if not provided
    if (currentTimeMinutes === undefined) {
        const currentTime = new Date().toTimeString().slice(0, 8); // Format: HH:MM:SS
        currentTimeMinutes = normalizeTimeToMinutes(currentTime);
        if (shouldLog) {
            console.log('🕒 Current time:', currentTime, '(', currentTimeMinutes, 'minutes)');
        }
    }

    // Sort reservations by start time
    const sortedReservations = [...tableReservations].sort((a, b) => {
        const aTime = normalizeTimeToMinutes(a.start_time);
        const bTime = normalizeTimeToMinutes(b.start_time);
        return aTime - bTime;
    });

    if (shouldLog) {
        console.log('Sorted reservations:', sortedReservations.map(r => ({
            id: r.id,
            start: r.start_time,
            end: r.end_time,
            name: r.name_of_the_person
        })));
    }

    // First check for a reservation that includes the current time
    const currentActiveReservation = sortedReservations.find(res => {
        const startMinutes = normalizeTimeToMinutes(res.start_time);
        let endMinutes = normalizeTimeToMinutes(res.end_time);

        // Handle overnight shifts
        if (endMinutes <= startMinutes) {
            endMinutes += 24 * 60;
            const result = currentTimeMinutes >= startMinutes || currentTimeMinutes <= endMinutes % (24 * 60);
            if (shouldLog) {
                console.log(`Overnight shift detected - Reservation ${res.id}: ${startMinutes} to ${endMinutes}, current: ${currentTimeMinutes}, overlap: ${result}`);
            }
            return result;
        }

        const result = currentTimeMinutes >= startMinutes && currentTimeMinutes <= endMinutes;
        if (shouldLog) {
            console.log(`Regular shift - Reservation ${res.id}: ${startMinutes} to ${endMinutes}, current: ${currentTimeMinutes}, overlap: ${result}`);
        }
        return result;
    });

    if (currentActiveReservation) {
        if (shouldLog) console.log('⭐ Found time-overlapping reservation:', currentActiveReservation.id);
        return currentActiveReservation;
    }

    // If no current overlap, find the next upcoming reservation
    const futureReservations = sortedReservations.filter(res => {
        const startMinutes = normalizeTimeToMinutes(res.start_time);
        return startMinutes > currentTimeMinutes;
    });

    if (futureReservations.length > 0) {
        // Get the closest future reservation
        if (shouldLog) console.log('🔜 Found upcoming reservation:', futureReservations[0].id);
        return futureReservations[0];
    }

    // If all reservations are in the past, return the most recent one
    if (shouldLog) console.log('⏪ All reservations are in the past, returning most recent:',
        sortedReservations[sortedReservations.length - 1]?.id);
    return sortedReservations[sortedReservations.length - 1];
}

/**
 * Finds the prioritized reservation for a table based on set criteria
 * @param table Table to find reservation for
 * @param reservations All available reservations
 * @param updatedReservation Recently updated reservation (optional)
 * @param hasPendingOrder Whether the updated reservation has pending orders (optional)
 * @param shouldLog Whether to log debugging information
 * @returns The prioritized reservation or null if none found
 */
export const findPrioritizedReservation = (
    table: ExtendedTableT | TableT,
    reservations: ExtendedReservationT[],
    updatedReservation?: ExtendedReservationT,
    hasPendingOrder?: boolean,
    shouldLog: boolean = true
): ExtendedReservationT | null => {
    if (shouldLog) {
        console.group(`🔍 Finding prioritized reservation for table: ${table.table_name}`);
        console.log('Table ID:', table.id);
        console.log('Total reservations to check:', reservations.length);
        if (updatedReservation) {
            console.log('Updated reservation:', updatedReservation);
        }
    }

    const currentTime = new Date().toTimeString().slice(0, 8);
    const currentMinutes = normalizeTimeToMinutes(currentTime);

    if (shouldLog) {
        console.log('Current time:', currentTime, `(${currentMinutes} minutes)`);
    }

    // PRIORITY 0: If updatedReservation is provided and active and belongs to this table
    if (updatedReservation && updatedReservation.is_active) {
        const tableId = typeof updatedReservation.table === 'object'
            ? updatedReservation.table.id
            : updatedReservation.table_id;

        const belongs = tableId === table.id || table.static_reservation_id === updatedReservation.id;

        if (shouldLog) {
            console.log('PRIORITY 0: Check if active updated reservation belongs to this table');
            console.log(`Updated reservation table ID: ${tableId}`);
            console.log(`Current table ID: ${table.id}, Static reservation ID: ${table.static_reservation_id}`);
            console.log(`Result: ${belongs ? 'YES - Belongs to this table' : 'NO - Does not belong to this table'}`);
        }

        if (belongs) {
            if (shouldLog) {
                console.log('✅ PRIORITY 0 MATCH: Active updated reservation found for this table');
                console.groupEnd();
            }
            return { ...updatedReservation, hasPendingOrder: hasPendingOrder || false };
        }
    }

    // PRIORITY 1: Already active reservations for this table (marked is_active=true)
    const activeReservationFilter = (r: ExtendedReservationT) => {
        const basicCheck = isSameTable(r, table) && isActiveReservation(r);
        const excludeUpdated = updatedReservation ? r.id !== updatedReservation.id : true;
        return basicCheck && excludeUpdated;
    };

    if (shouldLog) {
        console.log('PRIORITY 1: Checking for active reservations (is_active=true)');
    }

    const activeReservations = reservations.filter(activeReservationFilter);
    const activeReservation = activeReservations.length > 0 ? activeReservations[0] : null;

    if (shouldLog) {
        console.log(`Found ${activeReservations.length} active reservations`);
        if (activeReservations.length > 0) {
            console.log('Active reservations:', activeReservations.map(r => ({
                id: r.id,
                name: r.name_of_the_person,
                status: r.status
            })));
        }
    }

    if (activeReservation) {
        if (shouldLog) {
            console.log('✅ PRIORITY 1 MATCH: Active reservation found');
            console.groupEnd();
        }
        return activeReservation;
    }

    // PRIORITY 2: Reservations whose time window INCLUDES the current time
    const tableReservations = reservations.filter(r =>
        isSameTable(r, table) &&
        r.id !== (updatedReservation?.id ?? '') &&
        !r.is_active
    );

    if (shouldLog) {
        console.log('PRIORITY 2: Checking for reservations with time window including the current time');
        console.log(`Found ${tableReservations.length} total table reservations`);
    }

    // Direct check for current time overlap instead of using checkTimeOverlap
    const currentTimeActiveReservation = tableReservations.find(res => {
        const startMinutes = normalizeTimeToMinutes(res.start_time);
        let endMinutes = normalizeTimeToMinutes(res.end_time);

        // Handle overnight shifts
        if (endMinutes <= startMinutes) {
            endMinutes += 24 * 60;
            const result = currentMinutes >= startMinutes || currentMinutes <= endMinutes % (24 * 60);

            if (shouldLog) {
                console.log(`Overnight reservation ${res.id}: ${res.start_time} to ${res.end_time}, current: ${currentTime}, overlap: ${result}`);
            }

            return result;
        }

        // Regular shift check
        const result = currentMinutes >= startMinutes && currentMinutes <= endMinutes;

        if (shouldLog) {
            console.log(`Reservation ${res.id}: ${res.start_time} to ${res.end_time}, current: ${currentTime}, overlap: ${result}`);
        }

        return result;
    });

    // Also check if updated reservation fits this criteria using the same direct approach
    let updatedReservationOverlapsNow = false;
    if (updatedReservation && !updatedReservation.is_active) {
        const tableId = typeof updatedReservation.table === 'object'
            ? updatedReservation.table.id
            : updatedReservation.table_id;

        if (tableId === table.id || table.static_reservation_id === updatedReservation.id) {
            const startMinutes = normalizeTimeToMinutes(updatedReservation.start_time);
            let endMinutes = normalizeTimeToMinutes(updatedReservation.end_time);

            // Handle overnight shifts
            if (endMinutes <= startMinutes) {
                endMinutes += 24 * 60;
                updatedReservationOverlapsNow = currentMinutes >= startMinutes || currentMinutes <= endMinutes % (24 * 60);
            } else {
                updatedReservationOverlapsNow = currentMinutes >= startMinutes && currentMinutes <= endMinutes;
            }

            if (shouldLog && updatedReservationOverlapsNow) {
                console.log(`Updated reservation ${updatedReservation.id}: ${updatedReservation.start_time} to ${updatedReservation.end_time}, current: ${currentTime}, overlap: true`);
            }
        }
    }

    if (currentTimeActiveReservation || updatedReservationOverlapsNow) {
        const selectedReservation = updatedReservationOverlapsNow
            ? { ...updatedReservation, hasPendingOrder: hasPendingOrder || false }
            : currentTimeActiveReservation;

        if (shouldLog) {
            console.log('✅ PRIORITY 2 MATCH: Found reservation overlapping with current time:',
                selectedReservation.id);
            console.groupEnd();
        }

        return selectedReservation;
    }

    // PRIORITY 3: Future reservations
    let allTableReservations = [...tableReservations];

    // If updated reservation belongs to this table and is not active, include it for comparison
    if (updatedReservation && !updatedReservation.is_active) {
        const tableId = typeof updatedReservation.table === 'object'
            ? updatedReservation.table.id
            : updatedReservation.table_id;

        if (tableId === table.id || table.static_reservation_id === updatedReservation.id) {
            // Add updated reservation to the comparison pool
            if (!allTableReservations.some(r => r.id === updatedReservation.id)) {
                allTableReservations.push({...updatedReservation, hasPendingOrder: hasPendingOrder || false});
            }
        }
    }

    if (shouldLog) {
        console.log('PRIORITY 3: Checking for future reservations');
    }

    // Find future reservations
    const futureReservations = allTableReservations.filter(r => {
        const startMinutes = normalizeTimeToMinutes(r.start_time);
        return startMinutes > currentMinutes;
    });

    if (shouldLog) {
        console.log(`Found ${futureReservations.length} future reservations`);
        if (futureReservations.length > 0) {
            console.log('Future reservations:', futureReservations.map(r => ({
                id: r.id,
                name: r.name_of_the_person,
                start: r.start_time,
                end: r.end_time
            })));
        }
    }

    if (futureReservations.length > 0) {
        // Sort by start time and get the closest upcoming reservation
        const sortedFutureReservations = [...futureReservations].sort((a, b) => {
            const aTime = normalizeTimeToMinutes(a.start_time);
            const bTime = normalizeTimeToMinutes(b.start_time);
            return aTime - bTime;
        });

        const nextReservation = sortedFutureReservations[0];

        if (shouldLog) {
            console.log('✅ PRIORITY 3 MATCH: Found upcoming reservation:',
                `${nextReservation.id} (${nextReservation.name_of_the_person})`);
            console.groupEnd();
        }

        return nextReservation;
    }

    // PRIORITY 4: Past reservations
    if (shouldLog) {
        console.log('PRIORITY 4: Checking for past reservations');
    }

    // Only check for past reservations
    const pastReservations = allTableReservations.filter(r => {
        const endMinutes = normalizeTimeToMinutes(r.end_time);
        const startMinutes = normalizeTimeToMinutes(r.start_time);

        // Check if it's a past reservation
        if (endMinutes <= startMinutes) {
            // Overnight shift
            return currentMinutes > endMinutes && currentMinutes > startMinutes;
        }
        return currentMinutes > endMinutes;
    });

    if (pastReservations.length > 0) {
        // Sort by end time (descending) to get the most recent past reservation
        const sortedPastReservations = [...pastReservations].sort((a, b) => {
            const aEndTime = normalizeTimeToMinutes(a.end_time);
            const bEndTime = normalizeTimeToMinutes(b.end_time);
            return bEndTime - aEndTime; // Descending order
        });

        const mostRecentReservation = sortedPastReservations[0];

        if (shouldLog) {
            console.log('✅ PRIORITY 4 MATCH: Found most recent past reservation:',
                `${mostRecentReservation.id} (${mostRecentReservation.name_of_the_person})`);
            console.groupEnd();
        }

        return mostRecentReservation;
    }

    // PRIORITY 5: Static reservations
    const staticReservationFilter = (r: ExtendedReservationT) => {
        const basicCheck = isStaticReservation(r, table);
        const excludeUpdated = updatedReservation ? r.id !== updatedReservation.id : true;
        return basicCheck && excludeUpdated;
    };

    if (shouldLog) {
        console.log('PRIORITY 5: Checking for static reservations');
    }

    const staticReservations = reservations.filter(staticReservationFilter);
    const staticReservation = staticReservations.length > 0 ? staticReservations[0] : null;

    if (shouldLog) {
        console.log(`Found ${staticReservations.length} static reservations`);
        if (staticReservations.length > 0) {
            console.log('Static reservations:', staticReservations.map(r => ({
                id: r.id,
                name: r.name_of_the_person
            })));
        }
    }

    if (staticReservation) {
        if (shouldLog) {
            console.log('✅ PRIORITY 5 MATCH: Static reservation found');
            console.groupEnd();
        }
        return staticReservation;
    }

    if (shouldLog) {
        console.log('❌ NO MATCH: No suitable reservation found for this table');
        console.groupEnd();
    }

    return null;
}

export const findTableReservationData = (reservations: ExtendedReservationT[], table: ExtendedTableT | TableT) => {
    // Enable debug logging for specific tables to help find issues
    const shouldLog = table.table_name === '2';

    // Use the centralized function to find the prioritized reservation
    return findPrioritizedReservation(table, reservations, undefined, undefined, shouldLog);
};

/** Marks every property as required (non-optional). However property values can still be undefined. */
export function full<T>(x: T) {
    return x as Full<T>;
}
