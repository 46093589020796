import {
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'

import { useRouter } from 'next/router'

import { ApplicationContext } from '@/context/ApplicationContext'

import { LANGUAGES, LANGUAGE_LABELS } from '@/constants/constants'

import LoadingSpinner from '@/components/shared/LoadingSpinner/LoadingSpinner.tsx'

import { ApplicationState, Prettify, TLanguageOption } from '@/types/globalTypes.js'

/* Language options array, containing all available languages for the application */
export const allLanguageOptions = [
    {
        id: LANGUAGES.EN,
        value: LANGUAGES.EN,
        label: LANGUAGE_LABELS[LANGUAGES.EN],
        countryCode: 'GB',
        primary: true,
        disabled: true,
    },
    {
        id: LANGUAGES.BG,
        value: LANGUAGES.BG,
        label: LANGUAGE_LABELS[LANGUAGES.BG],
        countryCode: 'BG',
        primary: false,
        disabled: false,
    },
    {
        id: LANGUAGES.DE,
        value: LANGUAGES.DE,
        label: LANGUAGE_LABELS[LANGUAGES.DE],
        countryCode: 'DE',
        primary: false,
        disabled: false,
    },
    {
        id: LANGUAGES.FR,
        value: LANGUAGES.FR,
        label: LANGUAGE_LABELS[LANGUAGES.FR],
        countryCode: 'FR',
        primary: false,
        disabled: false,
    },
    {
        id: LANGUAGES.RU,
        value: LANGUAGES.RU,
        label: LANGUAGE_LABELS[LANGUAGES.RU],
        countryCode: 'RU',
        primary: false,
        disabled: false,
    },
    {
        id: LANGUAGES.RO,
        value: LANGUAGES.RO,
        label: LANGUAGE_LABELS[LANGUAGES.RO],
        countryCode: 'RO',
        primary: false,
        disabled: false,
    },
    {
        id: LANGUAGES.IT,
        value: LANGUAGES.IT,
        label: LANGUAGE_LABELS[LANGUAGES.IT],
        countryCode: 'IT',
        primary: false,
        disabled: false,
    },
] as const

// This import statement is used for type inference and keeping track of all the file structure to not miss any keys in the objects.
export type TranslationModule =
    | Prettify<typeof import('./en.ts')>
    | Prettify<typeof import('./bg.ts')>
    | Prettify<typeof import('./de.ts')>
    | Prettify<typeof import('./fr.ts')>
    | Prettify<typeof import('./ru.ts')>
    | Prettify<typeof import('./ro.ts')>
    | Prettify<typeof import('./it.ts')>

const TranslationContext = createContext<
    | {
          translations: TranslationModule
          languageLocale: string
          setLanguageLocale: Dispatch<SetStateAction<string>>
          loading: boolean
      }
    | undefined
>(undefined)


const TranslationProvider: React.FC<{
    children: React.ReactNode
    serverTranslations?: TranslationModule
    serverLocale?: string
}> = ({ children, serverTranslations, serverLocale }) => {
    const router = useRouter()
    const [loadedTranslations, setLoadedTranslations] = useState<{
        [locale: string]: TranslationModule
    }>({})

    // Determine initial locale, prioritizing server-provided locale
    const initialLocale = useMemo(() => {
        return (
            serverLocale ||
            allLanguageOptions.find((option) => option.value === router.locale)?.value ||
            allLanguageOptions[0].value
        )
    }, [router.locale, serverLocale])

    const [languageLocale, setLanguageLocale] = useState(initialLocale)
    /* We use the second state for the locale to only update it after the module is ready this way we prevent any context errors */
    const [activeLocale, setActiveLocale] = useState(languageLocale)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let isCurrent = true

        // If we have server-side translations for the initial locale, use them immediately
        if (serverTranslations && initialLocale) {
            setLoadedTranslations((prev) => ({
                ...prev,
                [initialLocale]: serverTranslations,
            }))
            setActiveLocale(initialLocale)
            setLoading(false)
            return // Skip dynamic import
        }

        // Dynamically load the translation module for the new locale
        import(`./${languageLocale}.ts`)
            .then((translationsModule: TranslationModule) => {
                if (isCurrent) {
                    setLoadedTranslations((prev) => ({
                        ...prev,
                        [languageLocale]: translationsModule,
                    }))
                    setActiveLocale(languageLocale) // Update the active locale once the module is loaded
                }
            })
            .catch((error) => {
                if (isCurrent) {
                    console.error(
                        `Failed to load translations for language ${languageLocale}:`,
                        error
                    )
                }
            })
            .finally(() => {
                if (isCurrent) {
                    setLoading(false)
                }
            })

        return () => {
            isCurrent = false
        }
    }, [languageLocale, serverTranslations, initialLocale])

    const providerValue = useMemo(
        () => ({
            translations: loadedTranslations[activeLocale],
            languageLocale: activeLocale,
            setLanguageLocale,
            loading,
        }),
        [loadedTranslations, activeLocale, loading]
    )

    if (loading) {
        return (
            <LoadingSpinner
                className="flex w-screen h-screen m-auto"
                svgClassName="m-auto h-8 w-8"
            />
        )
    }

    return (
        <TranslationContext.Provider value={providerValue}>{children}</TranslationContext.Provider>
    )
}
/* The hook retuns all translated texts by code. All available keys/texts in case of not passed value for key parameter
 * @param {*} languageCode
 * @param {*} key
 */
export const useGetTranslations = () => {
    const { translations } = useContext(TranslationContext)
    if (!translations) {
        throw new Error('Translations must be used within a TranslationProvider')
    }
    return translations
}
export const useTranslationContext = () => {
    const context = useContext(TranslationContext)
    if (!context) {
        throw new Error('Translation Context must be used within a TranslationProvider')
    }
    return context
}

export const useLanguageOptions = (): TLanguageOption[] => {
    const applicationState: ApplicationState | undefined =
        useContext(ApplicationContext)?.applicationState

    if (!applicationState) {
        throw new Error('applicationState must be used within a TranslationProvider')
    }

    const selectedLanguages = applicationState?.venue?.languages

    if (selectedLanguages && selectedLanguages.length > 0) {
        return allLanguageOptions.filter((option) => selectedLanguages.includes(option.value))
    } else {
        return Array.from(allLanguageOptions)
    }
}



export default TranslationProvider
