import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Draggable } from '../../ui/draggable'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { DraggableOverlay } from './DraggableOverlay'
import ResourceRowComponent from './ResourceRowComponent'
import { TimelineComponent } from './TimelineComponent'
import {
    calculateRowCollisions,
    calculateUpdatedItem,
    checkTimeOverlap,
    enforceBoundaries,
    getRowHeight,
    initializeResources,
    normalizeTimeToMinutes,
    recalculateCollisions,
    updateResources,
} from './helperFunctions'
import {
    DndContext,
    DragEndEvent,
    DragMoveEvent,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    pointerWithin,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { DragStartEvent } from '@dnd-kit/core/dist/types'
import { restrictToFirstScrollableAncestor, restrictToWindowEdges } from '@dnd-kit/modifiers'

import { GRID_SIZE, MINUTES_PER_GRID, PIXELS_PER_HOUR, ROW_HEIGHT } from '@/constants/constants'

import { ENUM_MODES } from '@/enums/Enums'

import { cn } from '@/utils/cn'

import useWindowSize from '@/hooks/useWindowSize'

import { Setter } from '@/types/globalTypes'

export enum Axis {
    All,
    Vertical,
    Horizontal,
}

export enum EDGE_TYPE {
    START = 'start',
    END = 'end',
}

interface CollisionGroup {
    resourceRowId: string
    items: Resource[]
    maxStack: number
    groupId: number
}

export interface CollisionMap {
    [key: string]: CollisionGroup
}

interface ResourceSchedulerComponentProps<
    ResourceRow extends { id: string; section?: string },
    ResourceItem extends Resource,
> {
    resourceRows: ResourceRow[]
    resources: ResourceItem[]
    resourceRowComponent: (resourceRow: ResourceRow) => React.ReactNode
    resourceItemComponent: (resource: ResourceItem) => React.ReactNode
    sectionComponent?: (props: {
        section: string
        children: React.ReactNode
        index: number
    }) => React.ReactNode
    sectionData?: { [section: string]: ResourceRow[] }
    pixelsPerHour?: number
    minutesPerGrid?: number
    gridSize?: number
    rowHeight?: number
    timelineWidth: number
    rowWidth?: number
    queryStartDate: Date
    queryEndDate: Date
    onCellClick?: (cellInfo: { startTime: Date; endTime: Date; resourceRowId: string }) => void
    onResourceClick?: (resourceId: string) => void
    allowCollisions?: boolean
    minDuration?: number
    loading?: boolean
    setResourceHistory?: Setter<ResourceItem[]>
    mode: ENUM_MODES
}

export interface Resource {
    resource_date: string
    start_time: string
    end_time: string
    id: string
    x_coordinate: number
    y_coordinate: number
    width?: number
    resourceRowId: string
    styles?: React.CSSProperties
    classNames?: string
    isResizing?: boolean
    is_active?: boolean
}

export const ResourceSchedulerComponent = <
    ResourceRow extends { id: string; section?: string },
    ResourceItem extends Resource & { is_active?: boolean },
>({
    resourceRows,
    resources,
    resourceRowComponent,
    resourceItemComponent,
    sectionComponent,
    sectionData,
    rowWidth = ROW_HEIGHT,
    pixelsPerHour = PIXELS_PER_HOUR,
    minutesPerGrid = MINUTES_PER_GRID,
    gridSize = GRID_SIZE,
    rowHeight = ROW_HEIGHT,
    timelineWidth,
    queryStartDate,
    queryEndDate,
    onCellClick,
    onResourceClick,
    setResourceHistory,
    allowCollisions = false,
    minDuration = 60,
    loading,
    mode,
}: ResourceSchedulerComponentProps<ResourceRow, ResourceItem>) => {
    const { width } = useWindowSize()
    const containerRef = useRef(null)
    const resizeStartPositionRef = useRef<{
        x: number
        width: number
        start_time?: string
        end_time?: string
    }>({ x: 0, width: 0 })

    // Initialize with empty arrays first
    const [resourceItems, setResourceItems] = useState<ResourceItem[]>([])
    const [collisionMap, setCollisionMap] = useState<CollisionMap>({})
    const [activeDragItem, setActiveDragItem] = useState<ResourceItem>({
        end_time: '',
        id: '',
        resource_date: '',
        resourceRowId: '',
        start_time: '',
        x_coordinate: 0,
        isResizing: false,
        is_active: false,
    } as ResourceItem)
    const [isCollision, setIsCollision] = useState(false)

    // Move initialization to useEffect
    useEffect(() => {
        const { resources: finalResources, collisionMap: newCollisionMap } = initializeResources(
            resources,
            queryStartDate,
            pixelsPerHour,
            allowCollisions,
            rowHeight
        )
        setResourceItems(finalResources)
        if (allowCollisions) {
            setCollisionMap(newCollisionMap)
        }
    }, [resources, queryStartDate, allowCollisions, pixelsPerHour, rowHeight])

    const handleStateChange = useCallback(
        (newState: ResourceItem[]) => {
            setResourceItems(newState)
            setResourceHistory?.(newState)
        },
        [setResourceHistory]
    )

    const resetSelectedActiveItem = () =>
        setActiveDragItem({
            end_time: '',
            id: '',
            resource_date: '',
            resourceRowId: '',
            start_time: '',
            x_coordinate: 0,
        } as ResourceItem)

    const checkCollision = useCallback(
        (draggingItem: ResourceItem, allItems: ResourceItem[]) => {
            const itemsInSameParent = allItems.filter(
                (item) =>
                    item.resourceRowId === draggingItem.resourceRowId && item.id !== draggingItem.id
            )

            // Find all items that overlap with the dragging item
            const collidingItems = itemsInSameParent.filter((item) =>
                checkTimeOverlap(
                    draggingItem.start_time,
                    draggingItem.end_time,
                    item.start_time,
                    item.end_time
                )
            )

            if (collidingItems.length > 0) {
                if (allowCollisions) {
                    const allOverlappingItems = [...collidingItems, draggingItem].sort((a, b) => {
                        const aStart = normalizeTimeToMinutes(a.start_time)
                        const bStart = normalizeTimeToMinutes(b.start_time)
                        return aStart - bStart || a.id.localeCompare(b.id)
                    })

                    setCollisionMap((prev) => {
                        // Find existing group index or create new one
                        const existingGroupIndex = Object.values(prev).findIndex(
                            (group) => group.resourceRowId === draggingItem.resourceRowId
                        )
                        const groupId =
                            existingGroupIndex >= 0 ? existingGroupIndex : Object.keys(prev).length

                        return {
                            ...prev,
                            [draggingItem.resourceRowId]: {
                                resourceRowId: draggingItem.resourceRowId,
                                items: allOverlappingItems,
                                maxStack: allOverlappingItems.length,
                                groupId, // Add groupId to the collision group
                            },
                        }
                    })
                    return false
                }
                setIsCollision(true)
                return true
            }

            if (collisionMap[draggingItem.resourceRowId]) {
                setCollisionMap((prev) => {
                    const { [draggingItem.resourceRowId]: _, ...rest } = prev
                    return rest
                })
            }

            setIsCollision(false)
            return false
        },
        [allowCollisions, collisionMap]
    )

    const handleDragStart = useCallback(
        (event: DragStartEvent) => {
            const { active } = event
            if (active.data.current.isResizing) {
                return
            }
            const draggedItem = resourceItems.find((item) => item.id === active.id)
            setResourceItems((prevResources) =>
                prevResources.map((resource) =>
                    resource.id === draggedItem.id
                        ? { ...resource, styles: { ...resource.styles, opacity: 0.5 } }
                        : resource
                )
            )
            setActiveDragItem(draggedItem)
        },
        [resourceItems]
    )

    const handleDragMove = useCallback(
        (event: DragMoveEvent) => {
            const { active, delta, over } = event

            if (active.data.current.isResizing) {
                handleResize(event)
                return
            }
            const updatedResource = resourceItems.find((resource) => resource.id === active.id)
            if (!updatedResource) {
                resetSelectedActiveItem()
                return
            }

            const parentId = over?.id as string

            const { collision, updatedResource: newResource } = calculateUpdatedItem({
                item: updatedResource,
                deltaX: delta.x,
                queryStartDate,
                queryEndDate,
                pixelsPerHour,
                gridSize,
                updateCoordinates: false, // Only update times
                parentId: parentId,
                edge: null,
            })
            setIsCollision(collision)
            setActiveDragItem(newResource)
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            resourceItems,
            queryStartDate,
            pixelsPerHour,
            gridSize,
            checkCollision,
            resetSelectedActiveItem,
        ]
    )

    // Simplify the handleDragEnd function
    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, delta, over } = event

            if (active.data.current.isResizing) {
                handleResizeEnd(event)
                return
            }

            if (!over) {
                resetSelectedActiveItem()
                const draggedItem = resourceItems.find((item) => item.id === active.id)
                setResourceItems((prevResources) =>
                    prevResources.map((resource) =>
                        resource.id === draggedItem?.id
                            ? { ...resource, styles: { ...resource.styles, opacity: 1 } }
                            : resource
                    )
                )
                return
            }

            const updatedResource = resourceItems.find((resource) => resource.id === active.id)
            if (!updatedResource) {
                resetSelectedActiveItem()
                return
            }

            const sourceRowId = updatedResource.resourceRowId
            const destinationRowId = over.id as string
            const { updatedResource: newResource, collision } = calculateUpdatedItem({
                item: updatedResource,
                deltaX: delta.x,
                queryStartDate,
                queryEndDate,
                pixelsPerHour,
                gridSize,
                updateCoordinates: true,
                edge: null,
                parentId: destinationRowId,
                styles: { opacity: 1 },
            })
            setIsCollision(collision)

            // First update the dragged resource position
            const baseUpdatedResources = resourceItems.map((resource) =>
                resource.id === active.id ? newResource : resource
            )

            // Calculate collisions for source row
            const sourceRowCollisions = calculateRowCollisions(
                baseUpdatedResources,
                sourceRowId,
                rowHeight
            )

            // Calculate collisions for destination row
            const destinationRowCollisions = calculateRowCollisions(
                sourceRowCollisions.finalResources,
                destinationRowId,
                rowHeight
            )

            let finalResources = destinationRowCollisions.finalResources as ResourceItem[]

            // Clean up collision groups and reset positions
            if (sourceRowId !== destinationRowId) {
                // Remove collision groups for source row
                setCollisionMap((prevMap) => {
                    const newMap = { ...prevMap }
                    Object.keys(newMap).forEach((key) => {
                        if (key.startsWith(`${sourceRowId}-`)) {
                            delete newMap[key]
                        }
                    })
                    return newMap
                })

                // Reset positions for resources in the source row
                const remainingSourceResources = finalResources.filter(
                    (resource) => resource.resourceRowId === sourceRowId
                )

                if (remainingSourceResources.length === 1) {
                    finalResources = finalResources.map((resource) => {
                        if (resource.resourceRowId === sourceRowId) {
                            return {
                                ...resource,
                                styles: {
                                    ...resource.styles,
                                    top: '1px',
                                    zIndex: 0,
                                },
                            }
                        }
                        return resource
                    })
                }
            }

            handleStateChange(finalResources)
            resetSelectedActiveItem()
            setIsCollision(false)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            resourceItems,
            queryStartDate,
            queryEndDate,
            pixelsPerHour,
            gridSize,
            rowHeight,
            handleStateChange,
            resetSelectedActiveItem,
        ]
    )

    const handleResizeStart = useCallback(
        (event: DragStartEvent) => {
            const { active } = event
            const resourceToResize = resourceItems.find(
                (resource) => resource.id === active?.data?.current?.parentId
            )

            if (resourceToResize) {
                resizeStartPositionRef.current = {
                    x: resourceToResize.x_coordinate,
                    width: resourceToResize.width,
                    start_time: resourceToResize.start_time,
                    end_time: resourceToResize.end_time,
                }
                setResourceItems((prevResources) =>
                    prevResources.map((resource) =>
                        resource.id === resourceToResize.id
                            ? { ...resource, styles: { ...resource.styles, zIndex: 40 } }
                            : resource
                    )
                )
            }
        },
        [resourceItems]
    )

    const handleResize = useCallback(
        (event: DragMoveEvent) => {
            const { active, delta } = event
            const updatedResource = resourceItems.find(
                (resource) => resource.id === active?.data?.current?.parentId
            )

            if (!updatedResource) return

            const edge = active?.data.current.edge

            const { updatedResource: newResource, collision } = calculateUpdatedItem({
                item: updatedResource,
                deltaX: delta.x,
                queryStartDate,
                queryEndDate,
                pixelsPerHour,
                gridSize,
                updateCoordinates: true,
                edge,
                parentId: updatedResource.resourceRowId,
                resizeStartPositionRef,
                minDuration,
            })
            setIsCollision(collision)

            // Calculate new state first
            const updatedResources = resourceItems.map((resource) =>
                resource.id === updatedResource.id ? newResource : resource
            )
            // Calculate new collision map
            const newCollisionMap = recalculateCollisions(updatedResources, rowHeight)
            // Update collision map
            setCollisionMap(newCollisionMap)
            // Update resources through history
            handleStateChange(updatedResources)
        },
        [
            resourceItems,
            queryStartDate,
            queryEndDate,
            pixelsPerHour,
            gridSize,
            minDuration,
            handleStateChange,
            rowHeight,
        ]
    )

    const handleResizeEnd = useCallback(
        (event: DragEndEvent) => {
            const { active } = event
            const resourceToResize = resourceItems.find(
                (resource) => resource.id === active?.data?.current?.parentId
            )

            if (!resourceToResize) return

            const updatedResource = {
                ...resourceToResize,
                styles: { ...resourceToResize.styles, zIndex: 0 },
            }

            if (enforceBoundaries(queryStartDate, queryEndDate, updatedResource)) {
                return
            }

            // Calculate the updates first
            const updatedResources = updateResources(
                resourceItems,
                updatedResource,
                checkCollision,
                collisionMap,
                rowHeight,
                allowCollisions
            )

            // Update collision map
            const newCollisionMap = recalculateCollisions(updatedResources, rowHeight)
            setCollisionMap(newCollisionMap)

            // Then update the state with the final result
            handleStateChange(updatedResources)

            setIsCollision(false)
            resizeStartPositionRef.current = {
                x: 0,
                width: 0,
                end_time: '',
                start_time: '',
            }
        },
        [
            allowCollisions,
            checkCollision,
            collisionMap,
            handleStateChange,
            queryEndDate,
            queryStartDate,
            resourceItems,
            rowHeight,
        ]
    )

    const modifiers = useMemo(() => [restrictToFirstScrollableAncestor, restrictToWindowEdges], [])

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                delay: mode === ENUM_MODES.VIEW ? Infinity : 300,
                tolerance: 100,
                distance: 10,
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: mode === ENUM_MODES.VIEW ? Infinity : 150,
                tolerance: 5,
                distance: 1,
            },
        }),
        useSensor(KeyboardSensor, {})
    )
    return (
        <DndContext
            autoScroll={{ acceleration: 150, enabled: true, layoutShiftCompensation: false }}
            collisionDetection={pointerWithin}
            sensors={sensors}
            onDragEnd={handleDragEnd}
            onDragMove={handleDragMove}
            onDragStart={(e) => {
                if (e.active.data.current?.isResizing) {
                    handleResizeStart(e)
                } else {
                    handleDragStart(e)
                }
            }}
            modifiers={modifiers}
        >
            <div className="max-h-dvh">
                <div className="w-full bg-base-100 dark:bg-gray-800">
                    {loading ? (
                        <LoadingSpinner className="flex w-full h-full" svgClassName="m-auto" />
                    ) : (
                        <div
                            className="grid overflow-scroll scrollbar-normal scrollbar-thumb-gray-300  max-w-[100dvw] relative"
                            ref={containerRef}
                        >
                            <TimelineComponent
                                queryStartDate={queryStartDate}
                                queryEndDate={queryEndDate}
                                containerWidth={pixelsPerHour}
                                rowHeight={rowHeight}
                                rowWidth={rowWidth}
                                gridSize={gridSize}
                                className="z-[50]"
                                containerRef={containerRef}
                            />
                            <div
                                className=" max-sm:max-h-[calc(95dvh-60px)] min-h-[calc(80dvh-100px)] max-h-[calc(80dvh-100px)]"

                            >
                                <div>

                                    <div className="relative">
                                        {Object.entries(sectionData).map(
                                            ([section, rows], index) =>
                                                sectionComponent
                                                    ? sectionComponent({
                                                          section,
                                                          index,
                                                          children: rows.map((resourceRow) => (
                                                              <ResourceRowComponent
                                                                  key={resourceRow.id}
                                                                  resourceRow={resourceRow}
                                                                  resourceRowComponent={
                                                                      resourceRowComponent
                                                                  }
                                                                  resourceItems={resourceItems}
                                                                  resourceItemComponent={
                                                                      resourceItemComponent
                                                                  }
                                                                  queryStartDate={queryStartDate}
                                                                  queryEndDate={queryEndDate}
                                                                  gridSize={gridSize}
                                                                  minutesPerGrid={minutesPerGrid}
                                                                  onCellClick={onCellClick}
                                                                  onResourceClick={onResourceClick}
                                                                  rowHeight={getRowHeight(
                                                                      resourceRow.id,
                                                                      collisionMap,
                                                                      rowHeight
                                                                  )}
                                                                  mode={mode}
                                                              />
                                                          )),
                                                      })
                                                    : null
                                        )}
                                        {!sectionComponent
                                            ? resourceRows.map((resourceRow) => (
                                                  <ResourceRowComponent
                                                      key={resourceRow.id}
                                                      resourceRow={resourceRow}
                                                      resourceRowComponent={resourceRowComponent}
                                                      resourceItems={resourceItems}
                                                      resourceItemComponent={resourceItemComponent}
                                                      queryStartDate={queryStartDate}
                                                      queryEndDate={queryEndDate}
                                                      gridSize={gridSize}
                                                      minutesPerGrid={minutesPerGrid}
                                                      onCellClick={onCellClick}
                                                      onResourceClick={onResourceClick}
                                                      rowHeight={getRowHeight(
                                                          resourceRow.id,
                                                          collisionMap,
                                                          rowHeight
                                                      )}
                                                      mode={mode}
                                                  />
                                              ))
                                            : null}
                                    </div>
                                </div>
                                <DraggableOverlay
                                    styles={{
                                        position: 'absolute',
                                        width: activeDragItem.width,
                                        maxWidth: width <= 1024 ? '90vw' : '75vw',
                                        zIndex: 999999,
                                    }}
                                    modifiers={modifiers}
                                    // container={width <= 1280 ? containerRef.current : undefined}
                                >
                                    <Draggable
                                        dragOverlay
                                        dragging
                                        isResizing={activeDragItem.isResizing}
                                        style={{
                                            transform: `translate(${activeDragItem.y_coordinate})`,
                                        }}
                                    >
                                        {resourceItemComponent({
                                            ...activeDragItem,
                                            classNames: cn(
                                                {
                                                    'border-error': isCollision,
                                                    'border-success border-2': !isCollision,
                                                    'max-w-[90vw]': width <= 1024,
                                                    'max-w-[75vw]': width > 1024,
                                                },
                                                'cursor-grabbing'
                                            ),
                                        })}
                                    </Draggable>
                                </DraggableOverlay>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </DndContext>
    )
}
