import { addMinutes, format, parse } from "date-fns"
import { CollisionMap, EDGE_TYPE, Resource } from "./ResourceSchedulerComponent"
import { cn } from "@/utils/cn"

export const normalizeTimeToMinutes = (time: string) => {
    // Ensure we only take HH:mm portion of the time string
    const timeString = time.slice(0, 5)
    const [hours, minutes] = timeString.split(':').map(Number)
    return hours * 60 + minutes
}

export const getTimeSpanMinutes = (start: string, end: string) => {
    const startMins = normalizeTimeToMinutes(start)
    let endMins = normalizeTimeToMinutes(end)

    // If end time is earlier than start time, it means it's the next day
    if (endMins <= startMins) {
        endMins += 24 * 60 // Add 24 hours worth of minutes
    }

    return { startMins, endMins }
}

export const getRowHeight =
    (rowId: string, collisionMap: CollisionMap, rowHeight: number) => {
        const rowGroups = Object.values(collisionMap).filter(
            (group) => group.resourceRowId === rowId
        )

        if (rowGroups.length === 0) return rowHeight + 'px'

        // Find the largest stack in any group for this row
        const maxStack = Math.max(...rowGroups.map((group) => group.maxStack))
        return maxStack * rowHeight + 'px'
    }

export const checkTimeOverlap = (
    firstShiftStart: string,
    firstShiftEnd: string,
    secondShiftStart: string,
    secondShiftEnd: string
) => {
    // Handle the slicing here instead of in the calling code
    let firstStartMinutes = normalizeTimeToMinutes(firstShiftStart)
    let firstEndMinutes = normalizeTimeToMinutes(firstShiftEnd)
    let secondStartMinutes = normalizeTimeToMinutes(secondShiftStart)
    let secondEndMinutes = normalizeTimeToMinutes(secondShiftEnd)

    // Check if shifts cross midnight
    const isFirstShiftOvernight = firstEndMinutes <= firstStartMinutes
    const isSecondShiftOvernight = secondEndMinutes <= secondStartMinutes

    // First, normalize any overnight shifts to a continuous timeline
    if (isFirstShiftOvernight) {
        firstEndMinutes += 24 * 60
    }
    if (isSecondShiftOvernight) {
        secondEndMinutes += 24 * 60
    }

    // If either shift is overnight, we need to check both day and night periods
    if (isFirstShiftOvernight || isSecondShiftOvernight) {
        // Case 1: Original timeline comparison
        const normalOverlap =
            Math.max(firstStartMinutes, secondStartMinutes) <
            Math.min(firstEndMinutes, secondEndMinutes)

        // Case 2: Second shift shifted back by 24 hours
        const shiftedBackOverlap =
            Math.max(firstStartMinutes, secondStartMinutes - 24 * 60) <
            Math.min(firstEndMinutes, secondEndMinutes - 24 * 60)

        // Case 3: Second shift shifted forward by 24 hours
        const shiftedForwardOverlap =
            Math.max(firstStartMinutes, secondStartMinutes + 24 * 60) <
            Math.min(firstEndMinutes, secondEndMinutes + 24 * 60)

        // Special case: When first shift ends after midnight and second shift starts before midnight
        if (isFirstShiftOvernight && !isSecondShiftOvernight) {
            const midnightOverlap = secondStartMinutes < firstEndMinutes % (24 * 60)
            return normalOverlap || shiftedBackOverlap || shiftedForwardOverlap || midnightOverlap
        }

        return normalOverlap || shiftedBackOverlap || shiftedForwardOverlap
    }

    // For non-overnight shifts, simple overlap check
    return (
        Math.max(firstStartMinutes, secondStartMinutes) <
        Math.min(firstEndMinutes, secondEndMinutes)
    )
}

export const calculateTimePositions = (
    startTime: string,
    endTime: string,
    queryStartDate: Date,
    pixelsPerHour: number
) => {
    const timeSpan = getTimeSpanMinutes(startTime, endTime)
    const queryStartMins = normalizeTimeToMinutes(format(queryStartDate, 'HH:mm:ss'))

    let minutesFromStart = timeSpan.startMins - queryStartMins
    // If start time is before query start and it's an overnight shift
    if (minutesFromStart < 0 && timeSpan.endMins > timeSpan.startMins) {
        minutesFromStart += 24 * 60
    }

    const minutesDuration = timeSpan.endMins - timeSpan.startMins

    const x_coordinate = (minutesFromStart / 60) * pixelsPerHour
    const width = (minutesDuration / 60) * pixelsPerHour

    return { x_coordinate, width }
}

export const snapToGrid = (value: number, gridSize: number) => {
    return Math.round(value / gridSize) * gridSize
}


// Update enforceBoundaries to check minimum duration
export const enforceBoundaries = (
    queryStartDate: Date,
    queryEndDate: Date,
    item: Resource,
    minimumDuration: number = 60
) => {
    const queryStart = format(queryStartDate, 'HH:mm:ss')
    const queryEnd = format(queryEndDate, 'HH:mm:ss')

    let queryStartMinutes = normalizeTimeToMinutes(queryStart)
    let queryEndMinutes = normalizeTimeToMinutes(queryEnd)
    let startMinutes = normalizeTimeToMinutes(item.start_time)
    let endMinutes = normalizeTimeToMinutes(item.end_time)

    // Check if shifts cross midnight
    const isItemOvernight = endMinutes <= startMinutes
    const isQueryOvernight = queryEndMinutes <= queryStartMinutes

    // Normalize all times to a 48-hour timeline
    if (isItemOvernight) {
        endMinutes += 24 * 60
    }
    if (isQueryOvernight) {
        queryEndMinutes += 24 * 60
    }

    // Calculate duration
    const durationInMinutes = endMinutes - startMinutes
    if (durationInMinutes < minimumDuration) {
        return true
    }

    // For overnight query periods, create a 48-hour window
    if (isQueryOvernight) {
        // Check if start time is within bounds
        const isStartValid =
            startMinutes >= queryStartMinutes ||
            startMinutes <= queryEndMinutes % (24 * 60)

        // Check if end time is within bounds, accounting for overnight
        const isEndValid =
            (endMinutes >= queryStartMinutes && endMinutes <= queryEndMinutes) ||
            (endMinutes % (24 * 60) <= queryEndMinutes % (24 * 60))

        return !(isStartValid && isEndValid)
    } else {
        // For same-day periods
        const normalizedEndMinutes = isItemOvernight ? endMinutes : endMinutes + (endMinutes < startMinutes ? 24 * 60 : 0)
        const isStartValid = startMinutes >= queryStartMinutes
        const isEndValid = normalizedEndMinutes <= queryEndMinutes + (queryEndMinutes < queryStartMinutes ? 24 * 60 : 0)

        return !(isStartValid && isEndValid)
    }
}


export const calculateRowCollisions = (
    resources: Resource[],
    rowId: string,
    rowHeight: number
): { finalResources: Resource[]; collisionGroups: Resource[][] } => {
    const rowResources = resources.filter((resource) => resource.resourceRowId === rowId)

    // Enhanced sorting function that considers overlap patterns
    const sortedResources = [...rowResources].sort((a, b) => {
        const aStartMins = normalizeTimeToMinutes(a.start_time)
        const aEndMins = normalizeTimeToMinutes(a.end_time)
        const bStartMins = normalizeTimeToMinutes(b.start_time)
        const bEndMins = normalizeTimeToMinutes(b.end_time)

        const aIsOvernight = aEndMins <= aStartMins
        const bIsOvernight = bEndMins <= bStartMins

        // If only one shift crosses midnight
        if (aIsOvernight !== bIsOvernight) {
            // Check if they overlap
            const overlap = checkTimeOverlap(
                a.start_time,
                a.end_time,
                b.start_time,
                b.end_time
            )

            if (overlap) {
                if (aIsOvernight) {
                    return bStartMins < aStartMins ? -1 : 1
                } else {
                    return aStartMins < bStartMins ? -1 : 1
                }
            }
            return aIsOvernight ? -1 : 1
        }

        return aStartMins - bStartMins || a.id.localeCompare(b.id)
    })

    const collisionGroups: Resource[][] = []
    let currentGroup: Resource[] = []

    sortedResources.forEach((resource) => {
        if (currentGroup.length === 0) {
            currentGroup = [resource]
        } else {
            const overlapsWithGroup = currentGroup.some((existingItem) =>
                checkTimeOverlap(
                    resource.start_time,
                    resource.end_time,
                    existingItem.start_time,
                    existingItem.end_time
                )
            )

            if (overlapsWithGroup) {
                const resourceStartMins = normalizeTimeToMinutes(resource.start_time)
                const insertIndex = currentGroup.findIndex(
                    (item) => normalizeTimeToMinutes(item.start_time) > resourceStartMins
                )

                if (insertIndex === -1) {
                    currentGroup.push(resource)
                } else {
                    currentGroup.splice(insertIndex, 0, resource)
                }
            } else {
                if (currentGroup.length > 1) {
                    collisionGroups.push([...currentGroup])
                }
                currentGroup = [resource]
            }
        }
    })

    if (currentGroup.length > 1) {
        collisionGroups.push(currentGroup)
    }

    // Update vertical positions
    const finalResources = resources.map((resource) => {
        if (resource.resourceRowId === rowId) {
            const affectingGroup = collisionGroups.find((group) =>
                group.some((item) => item.id === resource.id)
            )

            if (!affectingGroup) {
                return {
                    ...resource,
                    y_coordinate: 0,
                    styles: {
                        ...resource.styles,
                        top: '1px', // Keep 1px offset for standalone items
                        zIndex: 0,
                    },
                }
            }

            const position = affectingGroup.findIndex((item) => item.id === resource.id)
            const y_coordinate = position * rowHeight
            const top = position === 0 ? 1 : y_coordinate // Only first item gets 1px offset

            return {
                ...resource,
                y_coordinate,
                styles: {
                    ...resource.styles,
                    top: `${top}px`,
                    zIndex: position > 0 ? 10 + position : undefined,
                },
            }
        }
        return resource
    })

    return { finalResources, collisionGroups }
}


export const recalculateCollisions =
    (items: Resource[], rowHeight: number) => {
        const newCollisionMap: CollisionMap = {}

        // Get unique row IDs
        const rowIds = [...new Set(items.map((item) => item.resourceRowId))]

        rowIds.forEach((rowId, index) => {
            const { collisionGroups } = calculateRowCollisions(items, rowId, rowHeight)

            // Add each collision group to the map
            collisionGroups.forEach((group, groupIndex) => {
                const groupKey = `${rowId}-${groupIndex}`
                newCollisionMap[groupKey] = {
                    resourceRowId: rowId,
                    items: group,
                    maxStack: group.length,
                    groupId: index,
                }
            })
        })

        return newCollisionMap
    }


export const calculateVerticalPosition = (
    resource: Resource,
    collisionMap: CollisionMap,
    rowHeight: number,
    allowCollisions: boolean
) => {
    if (!allowCollisions) return { offset: 1, y_coordinate: 0 } // Keep 1px offset for non-collision mode

    const resourceGroups = Object.values(collisionMap).filter(
        (group) =>
            group.resourceRowId === resource.resourceRowId &&
            group.items.some((item) => item.id === resource.id)
    )

    if (resourceGroups.length === 0) return { offset: 1, y_coordinate: 0 } // Keep 1px offset for standalone items

    resourceGroups.sort((a, b) => {
        const aStart = normalizeTimeToMinutes(a.items[0].start_time)
        const bStart = normalizeTimeToMinutes(b.items[0].start_time)
        return aStart - bStart
    })

    const earliestGroup = resourceGroups[0]
    const position = earliestGroup.items.findIndex((item) => item.id === resource.id)

    // Only first item gets the 1px offset
    if (position === 0) {
        return { offset: 1, y_coordinate: 0 }
    }

    // All subsequent items align exactly with their position
    const y_coordinate = position * rowHeight
    return {
        offset: y_coordinate,
        y_coordinate,
    }
}


export const updateResources = <T extends Resource>(
    resources: T[],
    updatedResource: T,
    checkCollisionFn: (resource: Resource, resources: T[]) => boolean,
    collisionMap: CollisionMap,
    rowHeight: number,
    allowCollisions: boolean
) => {
    const hasCollision = checkCollisionFn(updatedResource, resources)

    if (hasCollision && !allowCollisions) {
        return resources.map((resource) => {
            if (resource.id === updatedResource.id) {
                return {
                    ...resource,
                    classNames: cn('border-error border-2'),
                    styles: { opacity: 1 },
                } as T
            }
            return resource
        })
    }

    return resources.map((resource) => {
        if (resource.resourceRowId === updatedResource.resourceRowId) {
            const { offset, y_coordinate } = calculateVerticalPosition(
                resource.id === updatedResource.id ? updatedResource : resource,
                collisionMap,
                rowHeight,
                allowCollisions
            )

            const styles = {
                ...resource.styles,
                position: 'absolute',
                top: `${offset}px`,
                zIndex: offset > 0 ? 10 + offset / rowHeight : undefined,
                opacity: resource.id === updatedResource.id ? 1 : resource.styles?.opacity,
            }


            if (resource.id === updatedResource.id) {
                return {
                    ...updatedResource,
                    y_coordinate,
                    styles,
                } as T
            }

            return {
                ...resource,
                y_coordinate,
                styles,
            } as T
        }
        return resource
    })
}

// Add the calculateUpdatedItem function to the helper functions
export const calculateUpdatedItem = <T extends Resource>(
    params: {
        item: T
        deltaX?: number
        queryStartDate: Date
        queryEndDate: Date
        pixelsPerHour: number
        gridSize: number
        updateCoordinates: boolean
        parentId: string
        styles?: React.CSSProperties
        edge?: EDGE_TYPE | null
        minDuration?: number
        resizeStartPositionRef?: React.MutableRefObject<{
            x: number
            width: number
            start_time?: string
            end_time?: string
        }>
    }
) => {
    const {
        item,
        deltaX = 0,
        queryStartDate,
        queryEndDate,
        pixelsPerHour,
        gridSize,
        edge = null,
        updateCoordinates = false,
        parentId,
        styles,
        resizeStartPositionRef,
        minDuration = 60,
    } = params

    let newStartTime = item.start_time
    let newEndTime = item.end_time

    if (edge && resizeStartPositionRef.current) {
        const snappedDelta = snapToGrid(deltaX, gridSize)
        const minutesDelta = Math.round((snappedDelta / pixelsPerHour) * 60)

        const originalStartTime = resizeStartPositionRef.current.start_time || item.start_time
        const originalEndTime = resizeStartPositionRef.current.end_time || item.end_time

        newStartTime = originalStartTime
        newEndTime = originalEndTime

        if (edge === EDGE_TYPE.START) {
            const parsedStartTime = parse(originalStartTime, 'HH:mm:ss', new Date())
            newStartTime = format(addMinutes(parsedStartTime, minutesDelta), 'HH:mm:ss')
        } else if (edge === EDGE_TYPE.END) {
            const parsedEndTime = parse(originalEndTime, 'HH:mm:ss', new Date())
            newEndTime = format(addMinutes(parsedEndTime, minutesDelta), 'HH:mm:ss')
        }
    } else {
        const snappedX = snapToGrid(item.x_coordinate + deltaX, gridSize)
        const minutesFromStart = (snappedX / pixelsPerHour) * 60
        newStartTime = format(addMinutes(queryStartDate, minutesFromStart), 'HH:mm:ss')
        newEndTime = format(
            addMinutes(
                parse(newStartTime, 'HH:mm:ss', new Date()),
                Math.max(60, (item.width / pixelsPerHour) * 60)
            ),
            'HH:mm:ss'
        )
    }

    const { x_coordinate, width } = calculateTimePositions(
        newStartTime,
        newEndTime,
        queryStartDate,
        pixelsPerHour
    )

    const updatedResource = {
        ...item, resourceRowId: parentId,
        ...(updateCoordinates && { x_coordinate, width }),
        start_time: newStartTime,
        end_time: newEndTime,
        styles: {
            ...item.styles,
            ...styles,
        },
    }

    if (enforceBoundaries(queryStartDate, queryEndDate, updatedResource, minDuration)) {
        return { updatedResource: { ...item, styles: { ...item.styles, opacity: 1 } }, collision: true }
    }

    return { updatedResource, collision: false }
}


export const initializeResources = <T extends Resource>(
    resources: T[],
    queryStartDate: Date,
    pixelsPerHour: number,
    allowCollisions: boolean,
    rowHeight: number
) => {
    // First calculate time positions for all resources
    const resourcesWithPositions = resources.map((resource) => ({
        ...resource,
        ...calculateTimePositions(
            resource.start_time,
            resource.end_time,
            queryStartDate,
            pixelsPerHour
        ),
    })) as T[]

    // If collisions are not allowed, return early with just positioned resources
    if (!allowCollisions) {
        return {
            resources: resourcesWithPositions,
            collisionMap: {}
        }
    }

    // Get unique row IDs
    const rowIds = [...new Set(resourcesWithPositions.map((item) => item.resourceRowId))]

    // Process each row
    let finalResources = [...resourcesWithPositions]
    rowIds.forEach((rowId) => {
        const { finalResources: updatedResources } = calculateRowCollisions(
            finalResources,
            rowId,
            rowHeight
        )
        finalResources = updatedResources as T[]
    })

    // Calculate collision map
    const collisionMap = recalculateCollisions(finalResources, rowHeight)

    return {
        resources: finalResources,
        collisionMap
    }
}

/*
const handleSaveChanges = async () => {
    try {
        const currentTime = format(new Date(), 'HH:mm:ss')
        const currentMinutes = normalizeTimeToMinutes(currentTime)

        // First create a map of original table assignments
        const originalTableAssignments = new Map(
            initialResources.map((res) => [res.id, res.resourceRowId])
        )

        // Map reservations by table
        const reservationsByTable = resources.reduce(
            (acc, res) => {
                if (!acc[res.resourceRowId]) {
                    acc[res.resourceRowId] = []
                }
                acc[res.resourceRowId].push(res)
                return acc
            },
            {} as Record<string, typeof resources>
        )

        // Process each table's reservations
        const updatedReservationsData = Object.entries(reservationsByTable).flatMap(
            ([tableId, tableReservations]) => {
                if (!tableReservations?.length) return []

                const table = tables.find((t) => t.id === tableId)
                const staticReservation = table?.static_reservation_id
                    ? reservations.find(
                          (r) => r.id === table.static_reservation_id && r.is_static
                      )
                    : null

                // Sort reservations by start time
                const sortedReservations = [...tableReservations].sort((a, b) => {
                    const aTime = normalizeTimeToMinutes(a.start_time)
                    const bTime = normalizeTimeToMinutes(b.start_time)
                    return aTime - bTime
                })

                // Find current active reservation (overlapping with current time)
                let activeReservation = sortedReservations.find((res) => {
                    const startMinutes = normalizeTimeToMinutes(res.start_time)
                    let endMinutes = normalizeTimeToMinutes(res.end_time)

                    // Handle overnight shifts
                    if (endMinutes <= startMinutes) {
                        endMinutes += 24 * 60
                        return (
                            currentMinutes >= startMinutes ||
                            currentMinutes <= endMinutes % (24 * 60)
                        )
                    }
                    return currentMinutes >= startMinutes && currentMinutes <= endMinutes
                })

                // If no current overlap, find next upcoming reservation
                if (!activeReservation) {
                    const futureReservations = sortedReservations.filter((res) => {
                        const startMinutes = normalizeTimeToMinutes(res.start_time)
                        return startMinutes > currentMinutes
                    })

                    // Check if all reservations are in the past
                    const allReservationsInPast = sortedReservations.every((res) => {
                        const endMinutes = normalizeTimeToMinutes(res.end_time)
                        const startMinutes = normalizeTimeToMinutes(res.start_time)

                        if (endMinutes <= startMinutes) {
                            // Overnight shift
                            return currentMinutes > endMinutes && currentMinutes > startMinutes
                        }
                        return currentMinutes > endMinutes
                    })

                    if (futureReservations.length > 0) {
                        // Get the closest future reservation
                        activeReservation = futureReservations[0]
                    } else if (allReservationsInPast && staticReservation) {
                        // If all reservations are in the past and there's a static reservation
                        // Return both the activated static reservation AND the deactivated past reservations
                        return [
                            {
                                id: staticReservation.id,
                                is_active: true,
                                table_id: tableId,
                            },
                            ...sortedReservations.map((reservation) => {
                                const {
                                    x_coordinate,
                                    y_coordinate,
                                    resourceRowId,
                                    resource_date,
                                    ...rest
                                } = reservation
                                return {
                                    ...rest,
                                    date_of_reservation: resource_date,
                                    table_id: resourceRowId,
                                    is_active: false,
                                }
                            }),
                        ]
                    } else {
                        // No reservations to activate
                        return sortedReservations.map((reservation) => {
                            const {
                                x_coordinate,
                                y_coordinate,
                                resourceRowId,
                                resource_date,
                                ...rest
                            } = reservation
                            return {
                                ...rest,
                                date_of_reservation: resource_date,
                                table_id: resourceRowId,
                                is_active: false,
                            }
                        })
                    }
                }

                // Map reservations with their new states
                return sortedReservations.map((reservation) => {
                    const {
                        x_coordinate,
                        y_coordinate,
                        resourceRowId,
                        resource_date,
                        ...rest
                    } = reservation
                    return {
                        ...rest,
                        date_of_reservation: resource_date,
                        table_id: resourceRowId,
                        is_active: reservation.id === activeReservation.id,
                    }
                })
            }
        )

        // Handle activating static reservations for tables that lost their reservations
        const tablesWithChanges = Array.from(originalTableAssignments.entries())
            .filter(([resId, oldTableId]) => {
                const reservation = updatedReservationsData.find((r) => r.id === resId)
                return reservation && reservation.table_id !== oldTableId
            })
            .map(([resId, oldTableId]) => {
                const originalTable = tables.find((t) => t.id === oldTableId)
                if (originalTable?.static_reservation_id) {
                    const staticReservation = reservations.find(
                        (r) =>
                            r.id === originalTable.static_reservation_id &&
                            r.is_static &&
                            !r.is_active
                    )
                    if (staticReservation) {
                        return {
                            id: staticReservation.id,
                            is_active: true,
                            table_id: oldTableId,
                        }
                    }
                }
                return null
            })
            .filter(Boolean)

        const allUpdates = [...updatedReservationsData, ...tablesWithChanges]
        await handleBulkUpdateReservation(allUpdates)
        setMode(ENUM_MODES.VIEW)
    } catch (error) {
        console.error('Failed to update reservations:', error)
    }
}
 */
