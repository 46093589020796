import floorPlanReducer from './floorPlanReducer.ts'
import reservationsReducer from './reservationReducer.ts'
import tablesReducer from './tablesReducer.ts'

import { ACTION_TYPES } from '@/constants/constants.ts'

import { findTableReservationData, getDefaultExtendedReservationStructure, getDefaultTableStructure } from '@/utils/stateUtils.ts'

import {
    ApplicationAction,
    ExtendedReservationT,
    InitReservationsDashboardDataPayload,
    ReservationsDashboardState,
} from '@/types/globalTypes.js'
import { normalizeTimeToMinutes } from '@/components/shared/ResourceSchedulerComponent/helperFunctions.ts'
import { getTranslation } from '@/utils/functionUtils.ts'

const reservationsDashboardReducer = (
    state: ReservationsDashboardState,
    action: ApplicationAction
): ReservationsDashboardState => {
    switch (action.type) {
        case ACTION_TYPES.INIT_RESERVATIONS_DASHBOARD_DATA: {
            const payload = action.payload as InitReservationsDashboardDataPayload
            const { sections, floors, tables, reservationData, selectedLanguage } = payload



            // Map tables with localization and reservations using our new helper function
            const mappedTables = tables.map((table) => {
                const tableSection = sections.find((section) => section.id === table.section_id)
                const tableFloor = floors.find((floor) => floor.id === tableSection?.floor_id)

                const sectionLocalization = getTranslation(
                    tableSection?.sections_localization,
                    selectedLanguage
                )

                const floorLocalization = getTranslation(
                    tableFloor?.floors_localization,
                    selectedLanguage
                )


                // Use our helper function to find the correct reservation
                const tableReservationData = findTableReservationData(reservationData, table)

                return {
                    ...table,
                    reservation: tableReservationData || getDefaultExtendedReservationStructure(),
                    section: sectionLocalization?.translated_name || '',
                    floor: floorLocalization?.translated_name || '',
                }
            }).toSorted((a, b) => {
                const nameA = a.table_name.toLowerCase()
                const nameB = b.table_name.toLowerCase()
                const matchA = RegExp(/\d+/).exec(nameA)
                const matchB = RegExp(/\d+/).exec(nameB)
                const numA = matchA ? parseInt(matchA[0], 10) : 0
                const numB = matchB ? parseInt(matchB[0], 10) : 0
                return numA !== numB ? numA - numB : nameA.localeCompare(nameB)
            })

              // Map reservations with their tables
              const mappedReservations = reservationData.map((reservation) => {
                const tableData = mappedTables.find(
                    (table) => table.static_reservation_id === reservation.id || table.id === reservation.table_id
                )

                return {
                    ...reservation,
                    table: tableData || getDefaultTableStructure(),
                }
            }).toSorted((a, b) => {
                const aStart = normalizeTimeToMinutes(a.start_time)
                const bStart = normalizeTimeToMinutes(b.start_time)
                return aStart - bStart
            }) as ExtendedReservationT[]

            return {
                ...state,
                floors,
                sections,
                tablesData: mappedTables,
                reservations: mappedReservations,
            }
        }

        case ACTION_TYPES.INSERT_RESERVATION:
        case ACTION_TYPES.UPDATE_RESERVATION:
        case ACTION_TYPES.DELETE_RESERVATION:
            return {
                ...state,
                ...reservationsReducer(state, action),
            }

        case ACTION_TYPES.INSERT_TABLE:
        case ACTION_TYPES.UPDATE_TABLE:
        case ACTION_TYPES.DELETE_TABLE:
            return {
                ...state,
                ...tablesReducer(state, action),
            }
        case ACTION_TYPES.INSERT_FLOOR:
        case ACTION_TYPES.UPDATE_FLOOR:
        case ACTION_TYPES.DELETE_FLOOR:
        case ACTION_TYPES.INSERT_SECTION:
        case ACTION_TYPES.UPDATE_SECTION:
        case ACTION_TYPES.DELETE_SECTION:
            return {
                ...state,
                ...floorPlanReducer(state, action),
            }
        default:
            return state
    }
}

export default reservationsDashboardReducer
