import {
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import OneSignal from 'react-onesignal'

/* Utils */
import { ROLE_TO_PATHNAMES } from '@/routes/routes.ts'

import { Session, SupabaseClient, User } from '@supabase/supabase-js'

import { useRouter } from 'next/router.js'

/* Hooks */
import { useGetTranslations } from '@/i18n/index'

import { ENUM_PATHNAMES, ENUM_ROLES, ENUM_TYPE, NOTIFICATION_TYPE } from '@/constants/constants.ts'

import { redirectToDashboard, userHasAccessToRoute } from '@/utils/functionUtils'

import LoadingSpinner from '@/components/shared/LoadingSpinner/LoadingSpinner'

/* Components */
import NotAuthenticatedPageComponent from '@/components/shared/NotAuthorizedPageComponent/NotAuthorizedPageComponent.tsx'

import { ProfileT, ToastNotificationComponentProps } from '@/types/globalTypes'

/* Types */
import { Database } from '@/types/supabase.types'

type AuthContextState = {
    isLoading: boolean
    authorized: boolean | null
    session: Session
    user: User
    profile: ProfileT
    setUser: Dispatch<SetStateAction<User>>
    setProfile: Dispatch<SetStateAction<ProfileT>>
    setSession: Dispatch<SetStateAction<Session>>
    signOut: () => void
    supabaseClient: SupabaseClient<Database>
}

type AuthProviderProps = {
    children: React.ReactNode
    supabaseClient: SupabaseClient<Database>
    setLoadingUser: Dispatch<SetStateAction<boolean>>
    setToastNotification: (notification: ToastNotificationComponentProps['options']) => void
}

export const AuthContext = createContext<AuthContextState | null>(null)

export const PUBLIC_PATHS = [
    ENUM_PATHNAMES.LOGIN,
    ENUM_PATHNAMES.RESERVATIONS,
    ENUM_PATHNAMES.BOOKING,
    ENUM_PATHNAMES.CATCH_ALL,
]

const AuthProvider: React.FC<AuthProviderProps> = ({
    children,
    supabaseClient,
    setLoadingUser,
    setToastNotification,
}) => {
    const { TOAST_NOTIFICATIONS_TEXT } = useGetTranslations()
    const router = useRouter()
    const [isLoading, setIsLoading] = useState(true)
    const [oneSignalInitialized, setOneSignalInitialized] = useState<boolean>(false)

    const [authorized, setAuthorized] = useState<boolean | null>(null)
    const [session, setSession] = useState<Session>(null)
    const [user, setUser] = useState<User>(null)
    const [profile, setProfile] = useState<ProfileT>({
        avatar_url: '',
        email: '',
        full_name: '',
        id: '',
        main_role: '',
        reservation_id: '',
        roles: [],
        updated_at: '',
        username: '',
        venue_id:''
    })

    /**
     * Initializes OneSignal SDK for a given Supabase User
     * @param profile Supabase User Profile
     */
    const initializeOneSignal = async (profile: ProfileT) => {
        if (
            oneSignalInitialized ||
            PUBLIC_PATHS.includes(router.pathname) ||
            process.env.NODE_ENV === 'development'
        ) {
            return
        }
        await OneSignal.init({
            appId: process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID,

            notifyButton: {
                enable: false,
            },
            allowLocalhostAsSecureOrigin: true,
        })

        await OneSignal.login(profile.id)
        const tags = {}

        if (profile.roles.includes(ENUM_ROLES.WAITER)) {
            tags[ENUM_TYPE.BROADCAST_WAITER] = true
        }
        if (profile.roles.includes(ENUM_ROLES.HOST)) {
            tags[ENUM_TYPE.BROADCAST_HOST] = true
        }
        OneSignal.User.addTags(tags)
        OneSignal.Slidedown.promptPush()
        setOneSignalInitialized(true)
    }

    useEffect(() => {
        if (
            router.pathname === ENUM_PATHNAMES.BOOKING ||
            router.pathname === ENUM_PATHNAMES.RESERVATIONS ||
            router.pathname === ENUM_PATHNAMES.CATCH_ALL ||
            router.pathname === ENUM_PATHNAMES.LOGIN
        ) {
            return
        }

        const {
            data: { subscription },
        } = supabaseClient.auth.onAuthStateChange((event, session) => {
            switch (event) {
                case 'SIGNED_IN':
                    // When signed in, set the session and the user will be fetched in another useEffect

                    setSession(session)
                    setUser(session.user)

                    break
                case 'SIGNED_OUT':
                    // When signed out, clear session and user info, and redirect to login
                    setIsLoading(true)
                    setSession(null)
                    setUser(null)
                    setProfile({
                        avatar_url: '',
                        email: '',
                        full_name: '',
                        id: '',
                        main_role: '',
                        reservation_id: '',
                        roles: [],
                        updated_at: '',
                        username: '',
                        venue_id:''
                    })
                    if (!PUBLIC_PATHS.includes(router.pathname)) {
                        router.push(ENUM_PATHNAMES.LOGIN)
                    }
                    break
                case 'INITIAL_SESSION':
                    // Handle initial session state
                    setSession(session)

                    if (!session) {
                        setAuthorized(PUBLIC_PATHS.includes(router.pathname))
                        setIsLoading(false)
                        setLoadingUser(false)
                    }

                    break
                default:
                    break
            }
        })

        return () => {
            subscription.unsubscribe()
        }
    }, [supabaseClient, setLoadingUser, setToastNotification, router])

    useEffect(() => {
        if (
            !session ||
            router.pathname === ENUM_PATHNAMES.BOOKING ||
            router.pathname === ENUM_PATHNAMES.CATCH_ALL
        ) {
            setLoadingUser(false)
            return
        }

        const fetchProfile = async () => {
            setIsLoading(true)
            try {
                const { data: profileData, error: profileError } = await supabaseClient
                    .from('profiles')
                    .select('*')
                    .eq('id', session.user.id)

                if (profileError) {
                    console.error('Error fetching profile:', profileError)
                    setToastNotification({
                        type: NOTIFICATION_TYPE.ERROR,
                        message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
                    })
                    return
                }

                if (profileData[0]) {
                    setUser(session.user)
                    setProfile(profileData[0])
                    initializeOneSignal(profileData[0])
                    setAuthorized(true) // Or any logic you have to determine authorization
                } else {
                    // Handle the case where profile data is empty
                    console.error('Profile data is empty')
                    setAuthorized(false)
                }
            } catch (error) {
                console.error('Error fetching profile:', error)
                setToastNotification({
                    type: NOTIFICATION_TYPE.ERROR,
                    message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
                })
                setAuthorized(false)
            } finally {
                setIsLoading(false)
                setLoadingUser(false)
            }
        }

        fetchProfile()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        session,
        supabaseClient,
        setUser,
        setProfile,
        setToastNotification,
        TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
        user,
        setLoadingUser,
        router.pathname,
    ])

    useEffect(() => {
        if (isLoading || !profile) return

        const hasRoleAccess = userHasAccessToRoute(
            profile.roles,
            router.pathname,
            ROLE_TO_PATHNAMES
        )
        const isPublicPath = PUBLIC_PATHS.includes(router.pathname)

        setAuthorized(hasRoleAccess || isPublicPath)
    }, [router.pathname, isLoading, profile])

    const value = useMemo(() => {
        return {
            supabaseClient,
            isLoading,
            setSession,
            session,
            authorized,
            user,
            profile,
            setUser,
            setProfile,
            signOut: () => supabaseClient.auth.signOut(),
        }
    }, [isLoading, profile, session, supabaseClient, user, authorized])

    /* if the user is using PWA we use this useffect to check redirect if he already has a session */
    useEffect(() => {
        const handlePWARedirect = async () => {
            // Check if the PWA query parameter is present
            if (router.query.PWA === 'true') {
                setIsLoading(true)
                // Ensure there's a delay to allow session restoration by Supabase
                const { data } = await supabaseClient.auth.getSession()

                if (data && data.session) {
                    const { data: profileData, error } = await supabaseClient
                        .from('profiles')
                        .select('*')
                        .eq('id', data.session.user.id)
                        .single()
                    if (!error && profileData) {
                        const userRole = profileData.roles[0]
                        setIsLoading(false)
                        await redirectToDashboard(userRole, router)
                    } else {
                        setIsLoading(false)
                        // Redirect to login if no profile found or on error
                        await router.push(ENUM_PATHNAMES.LOGIN)
                    }
                } else {
                    setIsLoading(false)
                    // If no session, redirect to login
                    await router.push(ENUM_PATHNAMES.LOGIN)
                }
            }
        }

        // This should run once on component mount and whenever the PWA query changes
        handlePWARedirect()
    }, [router, router.query.PWA, supabaseClient])

    /* We first check if the routes are public, this way we can utilize static render page and thus have SEO for the chosen pages */
    if (
        router.pathname === ENUM_PATHNAMES.BOOKING ||
        router.pathname === ENUM_PATHNAMES.RESERVATIONS ||
        router.pathname === ENUM_PATHNAMES.LOGIN ||
        router.pathname === ENUM_PATHNAMES.CATCH_ALL
    ) {
        return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    }

    if (authorized === null) {
        return (
            <LoadingSpinner
                className="flex w-screen h-screen m-auto"
                svgClassName="m-auto h-8 w-8"
            />
        )
    }

    if (authorized === false && isLoading === false) {
        return <NotAuthenticatedPageComponent />
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuthContext = (): AuthContextState => {
    const context = useContext(AuthContext)

    if (!context) {
        throw new Error('Auth Context must be used within as AuthProvider')
    }
    return context
}

export default AuthProvider
