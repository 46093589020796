/* eslint-disable react-hooks/rules-of-hooks */
import { BookingPageState, ExtendedEventsT, ExtendedTableT, ReservationSubmitValuesТ, TableAvailability, TableTypeAvailability, TimePreferencesType, ToastNotificationT } from '@/types/globalTypes'
import { CustomLens } from '../store'
import { Setter } from '@dhmk/zustand-lens'
import { NOTIFICATION_TYPE } from '@/constants/constants'
import bookingPageReducer from '../reducers/bookingPageReducer'
import { getInitialBookingPageState } from '@/utils/stateUtils'

import { vanillaTRPC as trpc } from '@/utils/trpc'
import { ENUM_ACTION_TYPES } from '@/enums/Enums'


// Define the slice shape with its actions
export type BookingPageSlice = {
    state: BookingPageState
    dispatch: (action: any) => void
    setEvents: Setter<ExtendedEventsT[]>
    setSelectedEvents: Setter<ExtendedEventsT[]>
    setSelectedTable: Setter<ExtendedTableT>
    setSelectedTimePreferences: Setter<{ start_time: string; duration: string, people: number } | null>
    setLoading: Setter<boolean>
    setShowTimePreferences: Setter<boolean>
    setShowFloorPlanMap: Setter<boolean>
    setReservationSteps: Setter<number>
    setConfirmedReservationPopup: Setter<boolean>
    setInitialPageLoad: Setter<boolean>
    setAvailableTablesData: Setter<TableAvailability[]>,
    setTableTypeAvailability: Setter<TableTypeAvailability[]>,
    handleCreateReservation: (values: ReservationSubmitValuesТ) => Promise<void>
    handleTimePreferencesSubmit: (data: TimePreferencesType & {
        queryStartDate: string
        queryEndDate: string
        dayOfWeek: number
    }) => Promise<void>
}


export const bookingPageSlice: CustomLens<BookingPageSlice> = (set, get, api, ctx, setGlobalState, getGlobalState) => {

    const getToastTexts = () => getGlobalState().applicationState.translations?.TOAST_NOTIFICATIONS_TEXT || {
        GENERIC_ERROR_MESSAGE: 'Error',
        GENERIC_SUCCESS_MESSAGE: 'Success'
    };

    const showErrorToast = (customMessage?: string) => {
        const toastTexts = getToastTexts();
        setToastNotification({
            type: NOTIFICATION_TYPE.ERROR,
            message: customMessage || toastTexts.GENERIC_ERROR_MESSAGE,
        });
    };

    const showSuccessToast = (customMessage?: string) => {
        const toastTexts = getToastTexts();
        setToastNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: customMessage || toastTexts.GENERIC_SUCCESS_MESSAGE,
        });
    };

    const setLoading = (loading: boolean) => {
        set((state) => ({
            state: { ...state.state, loading }
        }));
    };
    const setToastNotification = (notification: ToastNotificationT) =>
        getGlobalState().applicationState.setToastNotification(notification)

    const dispatch = (action: any) =>
        set((state) => ({
            ...state,
            state: bookingPageReducer(state.state, action),
        }))

    // Helper function to create state setters
    const createNestedSetter = <K extends keyof BookingPageState>(key: K) =>
        (value: BookingPageState[K]) => set((state) => ({
            ...state,
            state: {
                ...state.state,
                [key]: value,
            },
        }))
    const handleTimePreferencesSubmit = async (data: TimePreferencesType) => {
        try {
            setLoading(true)

            const venueId = getGlobalState().applicationState.venueId;
            const selectedLanguage = getGlobalState().applicationState.selectedLanguage;


            const [floors, sections, availabilityData] = await Promise.all([
                trpc.layout.getFloorsLayout.query(venueId),
                trpc.layout.getSectionsLayout.query({
                    venue_id: venueId,
                    selectedLanguage,
                }),
                trpc.bookings.calculateTableAvailability.query({
                    ...data,
                    venueId: venueId,
                })
            ])

            const { slots, tables, tableTypeAvailability } = availabilityData

            get().setAvailableTablesData(slots as TableAvailability[])
            // Store the table type availability data
            if (tableTypeAvailability) {
                get().setTableTypeAvailability(tableTypeAvailability as TableTypeAvailability[])
            }

            dispatch({
                type: ENUM_ACTION_TYPES.INIT_DATA,
                payload: {
                    reservationData: [],
                    tables,
                    floors,
                    sections,
                    orders: [],
                    selectedLanguage,
                },
            })
            get().setSelectedTimePreferences(data)
            get().setShowTimePreferences(false)
            getGlobalState().modalState.setOpenCustomerFloorPlanMapPopup(true)
            setLoading(false)
        } catch (error) {
            showErrorToast(error.message)
            setLoading(false)
        }
    }

    const handleCreateReservation = async (values: ReservationSubmitValuesТ) => {
        try {
            get().setLoading(true)

            const duration = get().state.selectedTimePreferences?.duration || '00:00'

            await trpc.bookings.bookReservation.mutate({ ...values, duration: duration })

            get().setConfirmedReservationPopup(true)
            showSuccessToast()
            get().setLoading(false)

        } catch (error) {
            console.error('Error creating reservation:', error)
            showErrorToast()
        } finally {
            get().setLoading(false)
        }
    }


    return {
        state: getInitialBookingPageState(),
        dispatch,
        setEvents: createNestedSetter('events'),
        setSelectedEvents: createNestedSetter('selectedEvents'),
        setSelectedTable: createNestedSetter('selectedTable'),
        setSelectedTimePreferences: createNestedSetter('selectedTimePreferences'),
        setLoading: createNestedSetter('loading'),
        setShowTimePreferences: createNestedSetter('showTimePreferences'),
        setShowFloorPlanMap: createNestedSetter('showFloorPlanMap'),
        setReservationSteps: createNestedSetter('reservationSteps'),
        setConfirmedReservationPopup: createNestedSetter('confirmedReservationPopup'),
        setInitialPageLoad: createNestedSetter('initialPageLoad'),
        setAvailableTablesData: createNestedSetter('availableTablesData'),
        setTableTypeAvailability: createNestedSetter('tableTypeAvailability'), // New setter
        handleCreateReservation,
        handleTimePreferencesSubmit
    }
}
