//@ts-nocheck
import {
    ApplicationAction,
    ExtendedEventsT,
    ExtendedReservationT,
    ExtendedTableT,
    ModifiedExtendedReservationT,
    ReservationCountT,
    ReservationsDashboardState,
    ReservationSubmitValuesТ,
    ReservationT,
    SectionT,
    TableT,
    ToastNotificationT,
} from '@/types/globalTypes'
import { CustomLens } from '../store'
import { getInitialReservationsDashboardState, getInitialReservationState, getInitialSelectedTableState, updateState } from '@/utils/stateUtils'
import { Setter } from '@dhmk/zustand-lens'
import { Row } from 'react-table'
import { ENUM_ACTION_TYPES, ENUM_RESERVATION_ACTIONS } from '@/enums/Enums'
import { NOTIFICATION_TYPE, STATUS_CONSTANTS } from '@/constants/constants'

import reservationsDashboardReducer from '../reducers/reservationsDashboardReducer'
import { formatDate } from '@/utils/calendarUtils'
import { PostgrestError } from '@supabase/supabase-js'
import { vanillaTRPC as trpc } from '@/utils/trpc'

// Define the slice state and actions
export type ReservationsSlice = {
    state: ReservationsDashboardState
    dispatch: (action: ApplicationAction) => void
    selectedReservation: ModifiedExtendedReservationT
    initialReservationState: ModifiedExtendedReservationT
    initialSelectedTableState: ExtendedTableT
    selectedTable: ExtendedTableT
    setSelectedReservation: Setter<ModifiedExtendedReservationT>
    setSelectedTable: Setter<ExtendedTableT>
    setShowTableComponent: Setter<boolean>
    setLockCurrentShift: Setter<boolean>
    setEvents: Setter<ExtendedEventsT[]>
    setSelectedEvents: Setter<ExtendedEventsT[]>
    setReservationsCountPerDay: Setter<ReservationCountT>
    setSelectedFlatRows: Setter<Row<ModifiedExtendedReservationT>[]>
    setLoading: Setter<boolean>
    setInitialPageLoad: Setter<boolean>,
    setSelectedDate: Setter<Date>;
    setMinDate: Setter<Date>;
    setMaxDate: Setter<Date>;
    setDateToday: Setter<Date>;
    setStartingHours: Setter<string>;
    setEndingHours: Setter<string>;
    handleCreateReservation: (values: ReservationSubmitValuesТ) => Promise<void>;
    handleSendEmail: (reservationId: string, customNote?: string) => Promise<void>;
    handleDeleteReservations: (ids: string[]) => Promise<void>;
    handleCopyDefaultTableLayout: () => Promise<void>;
    handleCreateTable: (values: ExtendedTableT) => Promise<void>;
    handleEditTable: (tableData: ExtendedTableT) => Promise<void>;
    handleDeleteTable: (tableId: string) => Promise<void>;
    handleCreateWalkInReservation: (reservation: ExtendedReservationT) => Promise<void>
    handleReserveTable: (reservation: ExtendedReservationT, tableId: string) => Promise<void>
    handleBulkUpdateReservation: (reservations: Partial<ExtendedReservationT>[], emailOptions?: {
        sendEmail: boolean;
        customNote?: string;
        isConfirmation?: boolean;
        isCancellation?: boolean;
    }) => Promise<void>
    handleUpdateReservation: (
        values: ReservationSubmitValuesТ,
        emailOptions?: {
            sendEmail: boolean;
            customNote?: string;
            isConfirmation?: boolean;
            isCancellation?: boolean;
        }
    ) => Promise<void>;
    handleDisableOrEnableAutoPinRenewal: (value: boolean) => Promise<void>
    handleGenerateNewPinCode: (static_reservation_id: string) => Promise<void>
    handleCompleteReservation: () => Promise<void>
    handleBulkUpdateTables: (sections: SectionT[], tables: TableT[]) => Promise<PostgrestError | null>
    onCancelActionType: () => void
    handleReservationActionsPopup: (resetReservation: boolean) => void
    resetSelectedTableAndReservation: () => void
    closeAllPopups: () => void
}


export const reservationsDashboardSlice: CustomLens<ReservationsSlice> = (set, get, api, ctx, setGlobalState, getGlobalState) => {

    // Helper functions
    const getToastTexts = () => getGlobalState().applicationState.translations?.TOAST_NOTIFICATIONS_TEXT || {
        GENERIC_ERROR_MESSAGE: 'Error',
        GENERIC_SUCCESS_MESSAGE: 'Success'
    };

    const showErrorToast = (customMessage?: string) => {
        const toastTexts = getToastTexts();
        setToastNotification({
            type: NOTIFICATION_TYPE.ERROR,
            message: customMessage || toastTexts.GENERIC_ERROR_MESSAGE,
        });
    };

    const showSuccessToast = (customMessage?: string) => {
        const toastTexts = getToastTexts();
        setToastNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: customMessage || toastTexts.GENERIC_SUCCESS_MESSAGE,
        });
    };

    const setLoading = (loading: boolean) => {
        set((state) => ({
            state: { ...state.state, loading }
        }));
    };

    const setToastNotification = (notification: ToastNotificationT) => getGlobalState().applicationState.setToastNotification(notification);

    const dispatch = (action: ApplicationAction) =>
        set((state) => ({
            ...state,
            state: reservationsDashboardReducer(state.state, action),
        }))


    const createSetter = <K extends keyof ReservationsSlice>(key: K) => (value: ReservationsSlice[K]) => set((state: ReservationsSlice) => ({
        ...state,
        [key]: value,
    }));
    const createNestedSetter = <K extends keyof ReservationsDashboardState>(key: K) => (value: ReservationsDashboardState[K]) => set((state: ReservationsSlice) => ({
        ...state,
        state: {
            ...state.state,
            [key]: updateState(state.state[key], value),
        },
    }));

    const resetSelectedTableAndReservation = () => {
        get().setSelectedTable(get().initialSelectedTableState)
        get().setSelectedReservation(get().initialReservationState)
    }

    const onCancelActionType = () => {
        getGlobalState().modalState.setReservationAction(null)
        resetSelectedTableAndReservation()
        closeAllPopups()
    }
    const handleReservationActionsPopup = (resetReservation = true) => {
        getGlobalState().modalState.setReservationActionsPopup(false)
        if (resetReservation) {
            setTimeout(() => {
                get().setSelectedReservation(get().initialReservationState)
            }, 200)
        }
    }

    const closeAllPopups = () => {
        getGlobalState().modalState.setOpenDisableStaticReservationPopup(false)
        getGlobalState().modalState.setOpenDrawer(false)
        getGlobalState().modalState.setOpenAssignReservationToTablePopup(false)
        getGlobalState().modalState.setOpenMoveReservationToAnotherTablePopup(false)
        getGlobalState().modalState.setIsReservationToBeRelocated(false)
        getGlobalState().modalState.setOpenViewPopup(false)
        getGlobalState().modalState.setIsTableToBeReserved(false)
        getGlobalState().modalState.setReservationAction(null)
        getGlobalState().modalState.setReservationActionsPopup(false);
    }

    const handleCreateReservation = async (values: ReservationSubmitValuesТ) => {
        try {
            setLoading(true);
            const reservationData = {
                id: values.id,
                name_of_the_person: values.name_of_the_person,
                email: values.email,
                phone_number: values.phone_number,
                date_of_reservation: values.date_of_reservation,
                start_time: values.start_time,
                end_time: values.end_time,
                people: values.people,
                status: values.status,
                extra_information: values.extra_information,
                is_temporary: values.is_temporary,
                venue_id: values.venue_id,
                created_by_role: values.created_by_role,
                table_id: values.table_id,
            } as ReservationT;

            const { data } = await trpc.reservations.create.mutate(reservationData);


            dispatch({
                type: ENUM_ACTION_TYPES.INSERT_RESERVATION,
                payload: { newReservation: data as ReservationT },
            });

            getGlobalState().modalState.setOpenCreateReservationPopup(false);
            get().setSelectedReservation(getInitialReservationState());
            showSuccessToast();
            setLoading(false);
        } catch (err) {
            showErrorToast();
        } finally {
            setLoading(false);
        }
    };

    const handleBulkUpdateReservation = async (
        reservations: ExtendedReservationT[],
        emailOptions?: {
            sendEmail: boolean;
            customNote?: string;
            isConfirmation?: boolean;
            isCancellation?: boolean;
        }
    ) => {
        try {
            setLoading(true);
            const { data } = await trpc.reservations.bulkUpdate.mutate({
                reservations,
                emailOptions
            });

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_RESERVATIONS,
                payload: { updatedReservations: data as ReservationT[] },
            });

            closeAllPopups();
            showSuccessToast();
        } catch (error) {
            showErrorToast();
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateReservation = async (
        values: ReservationSubmitValuesТ,
        emailOptions?: {
            sendEmail: boolean;
            customNote?: string;
            isConfirmation?: boolean;
            isCancellation?: boolean;
        }
    ) => {
        setLoading(true);

        try {
            const data = await trpc.reservations.update.mutate({
                id: values.id,
                data: {
                    ...values
                },
                emailOptions
            });

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
                payload: { updatedReservation: data as ReservationT },
            });

            getGlobalState().modalState.setOpenEditReservationPopup(false);
            getGlobalState().modalState.setOpenViewReservationPopup(false);
            getGlobalState().modalState.setReservationActionsPopup(false);

            showSuccessToast();
        } catch (err) {
            showErrorToast();
        } finally {
            setLoading(false);
        }
    };

    const handleSendEmail = async (reservationId: string, customNote?: string) => {
        try {
            setLoading(true)
            await trpc.email.sendReservationConfirmation.mutate({
                reservationId: reservationId,
                customNote: customNote,

            })

            showSuccessToast();
            getGlobalState().modalState.setReservationActionsPopup(false);

        } catch (error) {
            console.error('Failed to send confirmation email:', error)
            showErrorToast();
        } finally {
            setLoading(false)
        }
    }

    const handleDeleteReservations = async (ids: string[]) => {
        try {
            await trpc.reservations.deleteMultiple.mutate(ids);

            dispatch({
                type: ENUM_ACTION_TYPES.DELETE_RESERVATIONS,
                payload: { ids },
            });

            getGlobalState().modalState.setOpenDeleteReservationPopup(false);
            showSuccessToast();
        } catch (err) {
            showErrorToast(err.message);
        }
    };




    const handleGenerateNewPinCode = async (static_reservation_id: string) => {
        setLoading(true);

        try {
            const { data } = await trpc.pinCodes.generateNew.mutate({
                static_reservation_id
            });

            set((state) => ({
                selectedTable: {
                    ...state.selectedTable,
                    reservation: {
                        ...state.selectedTable.reservation,
                        id: state.selectedTable.static_reservation_id,
                        pin_code: data.new_pin_code,
                        guest_id: data.new_guest_id,
                    },
                },
            }));

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
                payload: {
                    updatedReservation: {
                        ...get().selectedReservation,
                        pin_code: data.new_pin_code,
                        guest_id: data.new_guest_id,
                    }
                },
            });

            showSuccessToast();
            getGlobalState().modalState.setOpenGenerateNewPinCode(false);
        } catch (err) {
            showErrorToast();
        } finally {
            setLoading(false);
        }
    };



    const handleBulkUpdateTables = async (sections: SectionT[], tables: TableT[]): Promise<PostgrestError | null> => {
        try {

            setLoading(true)
            const baseTables = tables.map((table) => {
                return {
                    id: table.id,
                    section_id: table.section_id,
                    table_name: table.table_name,
                    reserved: table.reserved,
                    waiter: table.waiter,
                    waiter_name: table.waiter_name,
                    is_static: table.is_static,
                    table_date: table.table_date,
                    venue_id: table.venue_id,
                    static_reservation_id: table.static_reservation_id,
                    styles: table.styles as Record<string, string>,
                    x_coordinate: table.x_coordinate,
                    y_coordinate: table.y_coordinate,
                    created_at: table.created_at,
                    min_capacity: table.min_capacity,
                    max_capacity: table.max_capacity,
                    pricing_rules: table.pricing_rules as Record<string, string>,
                    table_type: table.table_type,
                    base_fee: Number(table.base_fee),
                    hourly_rate: Number(table.hourly_rate),
                    minimum_spend: Number(table.minimum_spend),
                    extra_information: table.extra_information,
                    is_premium: table.is_premium,
                    is_reservable: table.is_reservable,
                }
            })
            await trpc.tables.bulkUpdate.mutate(baseTables);

            dispatch({
                type: ENUM_ACTION_TYPES.COPY_DEFAULT_TABLE_LAYOUT,
                payload: { newTables: baseTables },
            });

            showSuccessToast();
            setLoading(false)
            return null
        } catch (error) {
            showErrorToast(error.message);
            setLoading(false)
        }
    }



    const handleCopyDefaultTableLayout = async () => {
        try {
            setLoading(true);
            const selectedDate = get().state.selectedDate;

            const venueId = getGlobalState().applicationState.venueId;

            const { data } = await trpc.tables.copyLayout.mutate({
                date: formatDate(selectedDate),
                venueId
            });

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_TABLES,
                payload: { updatedTables: data as ExtendedTableT[] },
            });

            getGlobalState().modalState.setOpenCopyDefaultTableLayoutPopup(false);
            showSuccessToast();
        } catch (error) {
            showErrorToast(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleCreateTable = async (values: ExtendedTableT) => {

        try {
            setLoading(true)
            const selectedDate = get().state.selectedDate;
            const starting_hours = get().state.starting_hours;

            const tableData = {
                table_name: values.table_name,
                reserved: values.reserved,
                section_id: values.section_id,
                waiter: null,
                is_static: values.is_static,
                table_date: `${formatDate(selectedDate)}T${starting_hours}`,
                venue_id: values.venue_id,
                min_capacity: values.min_capacity,
                max_capacity: values.max_capacity,
            };
            const { data } = await trpc.tables.create.mutate(tableData);

            dispatch({
                type: ENUM_ACTION_TYPES.INSERT_TABLE,
                payload: { newTable: data as TableT },
            });
            getGlobalState().modalState.setOpenCreateTablePopup(false);
            showSuccessToast();
            setLoading(false);
        } catch (error) {
            showErrorToast(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleEditTable = async (tableData: ExtendedTableT) => {
        try {
            setLoading(true);
            const { reservation, section, floor, ...rest } = tableData

            await trpc.tables.update.mutate({
                id: tableData.id,
                data: { ...rest, styles: tableData.styles as Record<string, string>, pricing_rules: tableData.pricing_rules as Record<string, string> }
            });

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_TABLE,
                payload: { updatedTable: tableData },
            });
            getGlobalState().modalState.setOpenEditTablePopup(false);
            resetSelectedTableAndReservation();
            showSuccessToast();
        } catch (error) {
            showErrorToast();
            setLoading(false);
        }

    };

    const handleDeleteTable = async (tableId: string) => {
        try {
            setLoading(true);

            await trpc.tables.delete.mutate(tableId);

            dispatch({
                type: ENUM_ACTION_TYPES.DELETE_TABLE,
                payload: { deletedTableId: tableId },
            });
            getGlobalState().modalState.setOpenDeleteTablePopup(false);
            getGlobalState().modalState.setOpenEditTablePopup(false);
            resetSelectedTableAndReservation();
            showSuccessToast();
        } catch (error) {
            showErrorToast();
            setLoading(false)
        }
    };

    const handleReserveTable = async (reservation: ExtendedReservationT, tableId: string) => {
        try {

            const { pin_code, ...restOfData } = reservation;

            const updatedReservation = {
                ...restOfData,
                table_id: tableId,
            };

            setLoading(true);

            await trpc.reservations.update.mutate({
                id: reservation.id,
                data: {
                    id: reservation.id,
                    table_id: tableId,
                },
            })

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
                payload: { updatedReservation: { ...reservation, ...updatedReservation } },
            });

            resetSelectedTableAndReservation();
            closeAllPopups();
            setLoading(false);
        } catch (error) {
            showErrorToast();
            setLoading(false);
        }
    };

    const handleCreateWalkInReservation = async (values: ExtendedReservationT) => {
        try {


            setLoading(true);
            const reservationData = {
                date_of_reservation: values.date_of_reservation,
                people: values.people,
                status: STATUS_CONSTANTS.SEATED,
                extra_information: values.extra_information,
                start_time: values.start_time,
                end_time: values.end_time,
                is_temporary: false,
                is_walk_in: true,
                is_active: false,
                venue_id: values.venue_id,
                table_id: values.table_id,
            } as ReservationT;

            const { data } = await trpc.reservations.createWalkIn.mutate(reservationData);


            dispatch({
                type: ENUM_ACTION_TYPES.INSERT_RESERVATION,
                payload: { newReservation: data as ReservationT },
            });

            setLoading(false);

            getGlobalState().modalState.setOpenCreateWalkInReservationPopup(false);
            get().setSelectedReservation(getInitialReservationState());
            showSuccessToast();
        } catch (error) {
            setLoading(false);
            showErrorToast();
        }
    };

    const handleCompleteReservation = async () => {
        setLoading(true);

        try {
            const data = await trpc.reservations.update.mutate({
                id: get().selectedReservation.id,
                data: {
                    id: get().selectedReservation.id,
                    status: STATUS_CONSTANTS.COMPLETED,
                    waiter_responded: true,
                    table_id: null
                }
            });


            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
                payload: { updatedReservation: data as ReservationT },
            });

            getGlobalState().modalState.setOpenViewPopup(false);
            resetSelectedTableAndReservation();

            showSuccessToast();
        } catch (err) {
            showErrorToast();
        } finally {
            setLoading(false);
        }
    };

    const handleDisableOrEnableAutoPinRenewal = async (value: boolean) => {
        try {
            setLoading(true);
            const reservation = get().selectedReservation;

            await trpc.reservations.update.mutate({
                id: reservation.id,
                data: {
                    skip_auto_pin_renewal: value,
                }
            })

            dispatch({
                type: ENUM_ACTION_TYPES.UPDATE_RESERVATION,
                payload: { updatedReservation: { ...reservation, skip_auto_pin_renewal: value } },
            });
            setLoading(false);
            showSuccessToast();
            getGlobalState().modalState.setOpenAutoRenewalPinCodePopup(false);
        } catch (err) {
            showErrorToast();
            setLoading(false);
        }
    };



    return {
        state: getInitialReservationsDashboardState(),
        selectedReservation: getInitialReservationState(),
        selectedTable: getInitialSelectedTableState(),
        initialReservationState: getInitialReservationState(),
        initialSelectedTableState: getInitialSelectedTableState(),
        dispatch,
        setSelectedReservation: createSetter('selectedReservation'),
        setSelectedTable: createSetter('selectedTable'),
        setSelectedFlatRows: createNestedSetter('selectedFlatRows'),
        setReservationsCountPerDay: createNestedSetter('reservationsCountPerDay'),
        setEvents: createNestedSetter('events'),
        setShowTableComponent: createNestedSetter('showTableComponent'),
        setLockCurrentShift: createNestedSetter('lockCurrentShift'),
        setLoading: createNestedSetter('loading'),
        setInitialPageLoad: createNestedSetter('initialPageLoad'),
        setSelectedEvents: createNestedSetter('selectedEvents'),
        setSelectedDate: createNestedSetter('selectedDate'),
        setMinDate: createNestedSetter('minDate'),
        setMaxDate: createNestedSetter('maxDate'),
        setDateToday: createNestedSetter('dateToday'),
        setStartingHours: createNestedSetter('starting_hours'),
        setEndingHours: createNestedSetter('ending_hours'),
        handleCreateReservation,
        handleBulkUpdateReservation,
        handleUpdateReservation,
        handleSendEmail,
        handleDeleteReservations,
        handleGenerateNewPinCode,
        handleCopyDefaultTableLayout,
        handleCreateTable,
        handleDeleteTable,
        handleEditTable,
        handleReserveTable,
        handleDisableOrEnableAutoPinRenewal,
        handleCreateWalkInReservation,
        handleCompleteReservation,
        handleBulkUpdateTables,
        onCancelActionType,
        handleReservationActionsPopup,
        resetSelectedTableAndReservation,
        closeAllPopups,
    }
}
