//@ts-nocheck
import { CustomLens } from '../store';
import { Setter } from '@dhmk/zustand-lens';
import { ExtendedFloorLocalizationT, ExtendedReservationT, ExtendedSectionLocalizationT, ExtendedTableT, FloorLocalizationT, ReservationT, SectionLocalizationT, SectionT, TableT, TabT, TCreateOrEditTableSubmitValues, TMappedSectionLocalization, ToastNotificationT } from '@/types/globalTypes';
import { NOTIFICATION_TYPE } from '@/constants/constants';
import {
    getLocalizedTableData,
    updateFloorsState,
    updateSectionsState,
} from '@/utils/functionUtils.ts'
import { getDefaultExtendedReservationStructure, getDefaultTableStructure } from '@/utils/stateUtils';
import { ENUM_PAGE_MODES, ENUM_TIER } from '@/enums/Enums';
import { PostgrestError } from '@supabase/supabase-js';
import { isTable } from 'drizzle-orm';
import { vanillaTRPC as trpc } from '@/utils/trpc.ts'
import { cn } from '@/utils/cn';
import { VenueTableUpdate } from '@/server/schemas/venue-tables.schema';

export type DefaultLayoutPageSlice = {
    loading: boolean;
    floorPlanLoading: boolean;
    floors: ExtendedFloorLocalizationT[];
    sections: ExtendedSectionLocalizationT[];
    tablesData: ExtendedTableT[];
    reservations: ExtendedReservationT[]
    selectedReservation: ExtendedReservationT
    selectedTable: ExtendedTableT;
    selectedPageTab: TabT;
    openFloorIds: { [key: string]: boolean }
    selectedFloor: ExtendedFloorLocalizationT
    selectedSection: TMappedSectionLocalization
    pageMode: ENUM_PAGE_MODES
    setLoading: Setter<boolean>
    setFloors: Setter<ExtendedFloorLocalizationT[]>
    setSections: Setter<ExtendedSectionLocalizationT[]>
    setTablesData: Setter<TableT[]>
    setSelectedTable: Setter<ExtendedTableT>
    setSelectedReservation: Setter<ExtendedReservationT>
    setSelectedPageTab: Setter<TabT>
    setPageMode: Setter<ENUM_PAGE_MODES>
    fetchInitialData: (selectedLanguage: string) => Promise<void>
    handleCreateTable: (values: TCreateOrEditTableSubmitValues, selectedLanguage: string) => Promise<void>
    handleEditTable: (tableData: Partial<TableT>) => Promise<void>
    handleDeleteTable: (tableId: string) => Promise<void>
    handleGenerateNewPinCode: (static_reservation_id: string) => Promise<void>
    setOpenFloorIds: Setter<{ [key: string]: boolean }>
    setSelectedFloor: Setter<ExtendedFloorLocalizationT>
    setSelectedSection: Setter<TMappedSectionLocalization>
    handleToggleFloor: (floorId: string) => void
    handleCreateOrEditFloor: (values: {
        id: string
        venue_id: string
        floors_localization: FloorLocalizationT
    }) => Promise<void>
    handleDeleteFloor: (floorId: string) => Promise<void>
    handleCreateOrEditSection: (values: TMappedSectionLocalization) => Promise<void>
    handleDeleteSection: (sectionId: string) => Promise<void>
    setSectionClassnames: (section: { id: string; name: string }) => string
    handleBulkUpdateSectionsAndTables: (sections: SectionT[], tables: TableT[], selectedLanguage: string, venueId: string) => Promise<PostgrestError | null>;
    setTableClassNames: (table: TableT) => string
    handleUpdateTablesFromLayout: () => Promise<void>;
};

export const defaultLayoutPageSlice: CustomLens<DefaultLayoutPageSlice> = (set, get, api, ctx, setGlobal, getGlobalState) => {

    const createSetter = <K extends keyof DefaultLayoutPageSlice>(key: K) => (value: DefaultLayoutPageSlice[K]) => set((state: DefaultLayoutPageSlice) => ({
        ...state,
        [key]: value,
    }));

    const setToastNotification = (notification: ToastNotificationT) => getGlobalState().applicationState.setToastNotification(notification);

    // Helper functions
    const getToastTexts = () => getGlobalState().applicationState.translations?.TOAST_NOTIFICATIONS_TEXT || {
        GENERIC_ERROR_MESSAGE: 'Error',
        GENERIC_SUCCESS_MESSAGE: 'Success'
    };

    const showErrorToast = (customMessage?: string) => {
        const toastTexts = getToastTexts();
        setToastNotification({
            type: NOTIFICATION_TYPE.ERROR,
            message: customMessage || toastTexts.GENERIC_ERROR_MESSAGE,
        });
    };

    const showSuccessToast = (customMessage?: string) => {
        const toastTexts = getToastTexts();
        setToastNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            message: customMessage || toastTexts.GENERIC_SUCCESS_MESSAGE,
        });
    };

    const setTableClassNames = (table: TableT) => {

        const isPremium = table.table_type === ENUM_TIER.PREMIUM
        const isVIP = table.table_type === ENUM_TIER.VIP

        let classNames = cn('dark:bg-slate-700', {
            'dark:!bg-opacity-80 dark:!bg-slate-600': table.id === get().selectedTable.id,
            '!bg-red-500 dark:!bg-red-600': table.is_reservable === false,
            'border-2 !bg-blue-500 dark:!bg-blue-600 hover:shadow-blue-200 dark:hover:shadow-blue-800': isPremium,
            'border-2 !bg-purple-400 dark:!bg-purple-500 hover:shadow-purple-200 dark:hover:shadow-purple-800': isVIP,
            'hover:shadow-md transition-shadow': !isPremium && !isVIP,
        })

        return classNames
    }

    const fetchInitialData = async (selectedLanguage: string) => {
        try {
            const venueId = getGlobalState().applicationState.venueId;


            set({ loading: true });

            const [floors, sections, tablesData, reservationData] = await Promise.all([
                trpc.layout.getFloorsLayout.query(venueId),
                trpc.layout.getSectionsLayout.query({
                    venue_id: venueId,
                    selectedLanguage,
                }),
                trpc.defaultTables.getDefaultTablesLayout.query(venueId),
                trpc.reservations.getStatic.query(venueId)

            ])


            const mappedReservations = reservationData.map((reservation) => {

                const tableData = tablesData.find(
                    (table) => (table.static_reservation_id === reservation.id || table.id === reservation.table_id)
                )

                if (tableData) {
                    return {
                        ...reservation,
                        pin_code: reservation.pin_code,
                        table: tableData,
                    }
                }

                return {
                    ...reservation,
                    table: getDefaultTableStructure(),
                }


            }) as ExtendedReservationT[]

            const mappedTables = tablesData.map((table) => {

                const mappedTable = getLocalizedTableData(table as TableT, sections as ExtendedSectionLocalizationT[], floors as ExtendedFloorLocalizationT[], selectedLanguage)


                const tableReservationData = mappedReservations.find((reservation) => {
                    // Explicit check for 'table' being null or a string (possibly an ID comparison)
                    if (isTable(reservation.table) && reservation.is_active) {

                        const tableObj = reservation.table
                        return tableObj.id === table.id

                    } else if (reservation.table_id === table.id && reservation.is_active) {

                        return true

                    } else if (reservation.table_id === table.id && reservation.is_active === false && table.is_static === false) {
                        /* If no active reservations are set on the non static table we place the non active reservation */
                        if (mappedReservations.some((res) => res.table_id === table.id && res.is_active === true)) {
                            return
                        }
                        return true
                    }

                    // Fallback or additional logic if needed
                    return false
                })


                if (tableReservationData) {
                    return {
                        ...table,
                        ...mappedTable,
                        reservation: tableReservationData,
                    }
                } else {
                    return {
                        ...table,
                        ...mappedTable,
                        reservation: getDefaultExtendedReservationStructure(),
                    }
                }
            })


            set({ floors: floors as ExtendedFloorLocalizationT[], sections: sections as ExtendedSectionLocalizationT[], tablesData: mappedTables, reservations: mappedReservations, loading: false });
        } catch (error) {
            showErrorToast();
            set({ loading: false });
        }
    };

    const handleCreateTable = async (values: TCreateOrEditTableSubmitValues, selectedLanguage: string) => {
        const venueId = getGlobalState().applicationState.venueId;

        try {
            const tableData = {
                table_name: values.table_name,
                reserved: values.reserved,
                section_id: values.section_id,
                waiter: null,
                is_static: values.is_static,
                venue_id: venueId,
                base_fee: values.base_fee,
                hourly_rate: values.hourly_rate,
                minimum_spend: values.minimum_spend,
                table_type: values.table_type,
                extra_information: values.extra_information,
                min_capacity: values.min_capacity,
                max_capacity: values.max_capacity,
                is_premium: values.is_premium,
                min_duration: values.min_duration,
                max_duration: values.max_duration,

            } as TableT;

            // Use tRPC to create default table
            const { table, reservation } = await trpc.defaultTables.create.mutate(tableData) as { table: TableT, reservation: ReservationT };

            const mappedTable = getLocalizedTableData(table, get().sections, get().floors, selectedLanguage)
            const mappedReservation = { ...reservation, table: mappedTable }

            set((state) => ({
                ...state,
                tablesData: [...state.tablesData, { ...mappedTable, reservation: mappedReservation }],
                reservations: [...state.reservations, mappedReservation],
            }));

            getGlobalState().modalState.setOpenCreateTablePopup(false)
            showSuccessToast();
        } catch (error) {
            showErrorToast();
        }
    };

    const handleEditTable = async (tableData: TableT) => {
        try {
            // Use tRPC to update default table
            const data = await trpc.defaultTables.update.mutate({
                id: tableData.id,
                data: tableData as unknown,
            });

            set((state) => {
                const newState = structuredClone(state.tablesData);
                const reservations = structuredClone(state.reservations);
                const tableIndex = newState.findIndex((table) => table.id === data.id);

                const reservationIndex = reservations.findIndex((reservation) => reservation.table_id === data.id);

                const updatedReservation = reservations[reservationIndex];
                let updatedTable = newState[tableIndex];

                if (updatedReservation) {
                    updatedTable = { ...newState[tableIndex], ...data as TableT, reservation: updatedReservation };
                } else {
                    updatedTable = { ...newState[tableIndex], ...data as TableT, reservation: getDefaultExtendedReservationStructure() };
                }

                newState[tableIndex] = updatedTable;

                return {
                    ...state,
                    tablesData: newState,
                    selectedTable: getDefaultTableStructure()
                };
            });

            getGlobalState().modalState.setOpenEditTablePopup(false)
            showSuccessToast();
        } catch (error) {
            showErrorToast();
        }
    };

    const handleDeleteTable = async (tableId: string) => {
        try {
            // Use tRPC to delete default table
            await trpc.defaultTables.delete.mutate(tableId);

            set((state) => ({
                tablesData: state.tablesData.filter((table) => table.id !== tableId),
                selectedTable: getDefaultTableStructure()
            }));

            getGlobalState().modalState.setOpenDeleteTablePopup(false)
            showSuccessToast();
        } catch (error) {
            showErrorToast();
        }
    };


    const handleGenerateNewPinCode = async (static_reservation_id: string) => {
        try {
            set({ loading: true });
            const { data } = await trpc.pinCodes.generateNew.mutate({
                static_reservation_id
            });

            set((state) => ({
                ...state,
                reservations: state.reservations.map((reservation) => {
                    if (reservation.id === static_reservation_id) {
                        return {
                            ...reservation,
                            pin_code: data.new_pin_code,
                            guest_id: data.new_guest_id,
                        };
                    }
                    return reservation;
                }),
                selectedTable: {
                    ...state.selectedTable,
                    reservation: {
                        ...state.selectedTable.reservation,
                        id: state.selectedTable.static_reservation_id,
                        pin_code: data.new_pin_code,
                        guest_id: data.new_guest_id,
                    },
                },
            }));

            getGlobalState().modalState.setOpenGenerateNewPinCode(false)
            getGlobalState().modalState.setOpenCreateTablePopup(false)
            set({ loading: false });
            showSuccessToast();
        } catch (error) {
            showErrorToast(error.message);
            set({ loading: false });
        }

    };

    const handleToggleFloor = (floorId: string) => {
        set((state) => ({
            openFloorIds: {
                ...state.openFloorIds,
                [floorId]: !state.openFloorIds[floorId],
            },
            selectedSection: {
                id: '',
                floor_id: '',
                styles: {},
                x_coordinate: 0,
                y_coordinate: 0,
                sections_localization: {},
            },
        }))
    }

    const handleCreateOrEditFloor = async (values: {
        id: string
        venue_id: string
        floors_localization: FloorLocalizationT
    }) => {
        set({ floorPlanLoading: true })

        try {
            // Use tRPC to create or update floor
            const localizationData = await trpc.layout.createOrUpdateFloor.mutate(values) as FloorLocalizationT[];

            set((state) => ({
                floors: updateFloorsState(state.floors, localizationData),
                floorPlanLoading: false,
            }));

            getGlobalState().modalState.setOpenEditFloorPopup(false)
            getGlobalState().modalState.setOpenCreateFloorPopup(false)
            showSuccessToast();
        } catch (error) {
            showErrorToast();
            set({ floorPlanLoading: false });
        }
    }

    const handleDeleteFloor = async (floorId: string) => {
        set({ floorPlanLoading: true })

        try {
            // Use tRPC to delete floor
            await trpc.layout.deleteFloor.mutate(floorId);

            set((state) => ({
                floors: state.floors.filter((floor) => floor.id !== floorId),
                floorPlanLoading: false,
            }));

            getGlobalState().modalState.setOpenDeleteFloorPopup(false)
            showSuccessToast();
        } catch (error) {
            showErrorToast();
            set({ floorPlanLoading: false });
        }
    }

    const handleCreateOrEditSection = async (values: TMappedSectionLocalization) => {
        set({ floorPlanLoading: true })

        try {
            // Use tRPC to create or update section
            const localizationData = await trpc.layout.createOrUpdateSection.mutate(values) as SectionLocalizationT[];

            set((state) => ({
                sections: updateSectionsState(state.sections, localizationData),
                floorPlanLoading: false,
            }));

            getGlobalState().modalState.setOpenCreateSectionPopup(false)
            getGlobalState().modalState.setOpenEditSectionPopup(false)
            showSuccessToast();
        } catch (error) {
            showErrorToast();
            set({ floorPlanLoading: false });
        }
    }



    const handleDeleteSection = async (sectionId: string) => {
        set({ floorPlanLoading: true })

        try {
            // Use tRPC to delete section
            await trpc.layout.deleteSection.mutate(sectionId);

            set((state) => ({
                sections: state.sections.filter((section) => section.id !== sectionId),
                floorPlanLoading: false,
            }));

            getGlobalState().modalState.setOpenDeleteSectionPopup(false)
            showSuccessToast();
        } catch (error) {
            showErrorToast();
            set({ floorPlanLoading: false });
        }
    }

    const setSectionClassnames = (section: { id: string; name: string }) => {
        let classNames = ''

        if (section.id === get().selectedSection.id) {
            // Check if the table is selected
            classNames += ' !bg-opacity-20 !bg-slate-300'
        }

        return classNames
    }

    const handleBulkUpdateSectionsAndTables = async (sections: SectionT[], tables: TableT[], selectedLanguage: string, venueId: string): Promise<PostgrestError | null> => {
        set({ floorPlanLoading: true });

        try {
            const mappedSections = sections.map((section) => ({ ...section, venue_id: venueId }))
            // Use tRPC for bulk update of sections
            const sectionResponse = await trpc.layout.bulkUpdateSections.mutate(mappedSections);

            let updatedSections = get().sections.map((section) => {
                const updatedSection = sectionResponse.find((updatedSection) => updatedSection.id === section.id);
                return updatedSection || section;
            });

            const mappedTables = tables.map((table) => {
                const { section, floor, reservation, ...rest } = table as ExtendedTableT
                return rest
            });

            // Use tRPC for bulk update of tables
            const data = await trpc.defaultTables.bulkUpdate.mutate(mappedTables as unknown as TableT[]) as TableT[];

            let updatedTables = get().tablesData.map((table) => {
                const updatedTable = data.find((updatedTable) => updatedTable.id === table.id);
                let mappedTable = getLocalizedTableData(updatedTable || table, get().sections, get().floors, selectedLanguage)
                return { ...mappedTable, reservation: getDefaultExtendedReservationStructure() };
            });

            set({
                floors: get().floors,
                sections: updatedSections,
                tablesData: updatedTables,
                floorPlanLoading: false
            });

            showSuccessToast();
            return null;
        } catch (error) {
            showErrorToast();
            set({ floorPlanLoading: false });
            return error;
        }
    };

    const handleUpdateTablesFromLayout = async () => {
        const venueId = getGlobalState().applicationState.venueId;
        const selectedLanguage = getGlobalState().applicationState.selectedLanguage;

        set({ floorPlanLoading: true });

        try {
            // Use tRPC to update tables from layout
            await trpc.tables.updateTablesFromLayout.mutate({
                venueId,
                intervalDays: 120
            });

            // Refresh the data after update
            await fetchInitialData(selectedLanguage);

            getGlobalState().modalState.setOpenUpdateLayoutPopup(false);
            showSuccessToast();
        } catch (error) {
            showErrorToast();
        } finally {
            set({ floorPlanLoading: false });
        }
    };

    return {
        loading: true,
        floorPlanLoading: false,
        floors: [],
        sections: [],
        tablesData: [],
        reservations: [],
        selectedReservation: getDefaultExtendedReservationStructure(),
        selectedTable: getDefaultTableStructure(),
        selectedPageTab: { id: 0, label: '' },
        pageMode: ENUM_PAGE_MODES.ADMIN,
        openFloorIds: {},
        selectedFloor: {
            id: '',
            floors_localization: [],
        },
        selectedSection: {
            id: '',
            floor_id: '',
            sections_localization: {},
            styles: {},
            x_coordinate: 0,
            y_coordinate: 0,
        },
        setLoading: createSetter('loading'),
        setFloors: createSetter('floors'),
        setSections: createSetter('sections'),
        setTablesData: createSetter('tablesData'),
        setSelectedTable: createSetter('selectedTable'),
        setSelectedPageTab: createSetter('selectedPageTab'),
        setOpenFloorIds: createSetter('openFloorIds'),
        setSectionClassnames,
        setSelectedFloor: createSetter('selectedFloor'),
        setSelectedReservation: createSetter('selectedReservation'),
        setSelectedSection: createSetter('selectedSection'),
        setPageMode: createSetter('pageMode'),
        setTableClassNames,
        handleToggleFloor,
        handleCreateTable,
        handleEditTable,
        handleDeleteTable,
        handleGenerateNewPinCode,
        handleCreateOrEditFloor,
        handleDeleteFloor,
        handleCreateOrEditSection,
        handleDeleteSection,
        handleBulkUpdateSectionsAndTables,
        handleUpdateTablesFromLayout,
        fetchInitialData,
    };
};
